import React from 'react'

export default function TableTradeEntry(props) {

  let {trade} = props;

  let entry_date = new Date(trade.entry.time)
  let entry_dt = entry_date.toUTCString()
  // let entry_mins = entry_date.getMinutes()
  // if (entry_mins < 10){
  //   entry_mins = '0'+entry_mins
  // }
  // let entry_dt = entry_date.toDateString() +' '+ entry_date.getHours() + ":" +  entry_mins
  let profit = 1
  let exit_dt
  if(trade.exit !== null){
    let exit_date = new Date(trade.exit.time)
    exit_dt = exit_date.toUTCString()
    // let exit_mins = exit_date.getMinutes()
    // if (exit_mins < 10){
    //   exit_mins = '0'+exit_mins
    // }
    // let exit_dt = exit_date.toDateString() + ' ' + exit_date.getHours() + ":" + exit_mins
    profit = trade.exit.price / trade.entry.price
  }
  let displayProfit = Math.round((profit - 1) * 10000) / 100
  let fontColor = 'green'
  if (trade.entry.type === 'long' && profit < 1){
    fontColor = 'red'
  } else if (trade.entry.type === 'short' && profit > 1){
    fontColor = 'red'
  }
  return <tbody key={trade.number}>
    {/* <hr/> */}
            {trade.entry ?
              <tr>
                <td>{trade.number}</td>
                <td>Entry: {trade.entry.type}</td>
                <td>{entry_dt}</td>
                <td>{trade.entry.price}</td>
                <td></td>
              </tr>
              : null}
            {trade.exit ?
              <tr>
                <td> </td>
                <td>Exit: {trade.exit.type}</td>
                <td>{exit_dt}</td>
                <td>{trade.exit.price}</td>
                {fontColor == 'red' ? 
                <td style={{color:'red', fontWeight:'bold' }}> {displayProfit} %</td>
                :
                <td style={{color:'green', fontWeight:'bold' }}> {displayProfit} %</td>}
              </tr>
            : null}
          </tbody>
      
}
