import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import '../App.css';

function LoginForm(props){
  let { onSuccesfulLogin } = props;
  const [pwd, setPwd] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    fetch('/auth/login', {
      method: 'POST', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({email:email, password: pwd})
    }).then(res => res.json()).then(response => {
      setLoading(false);
      if(!response['ok']){
        setErrorMessage(response['msg']);
      } else {
        let userData = response['data'];
        // console.log(userData);
        onSuccesfulLogin(userData);
      }
    });
    setValidated(true);
  };

  return (
      <Form validated={validated}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control required type="email" placeholder="Enter email" onChange={(event) => setEmail(event.target.value)}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control required type="password" placeholder="Password" onChange={(event) => setPwd(event.target.value)}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formError">
          <Form.Text className="text-muted" style={{color:'red'}}>{errorMessage}</Form.Text>
        </Form.Group>
        <Button type="submit" variant="primary" onClick={handleSubmit} disabled={loading}>
          {loading && <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />}
          Submit
        </Button>
      </Form>
  )
}


function Login(props){
  let { onSuccesfulLogin } = props;
  return (
    <div className="Full-section Flex-central-central">
      <LoginForm onSuccesfulLogin={onSuccesfulLogin}/>
    </div>
  );
}

export default Login;