import React, {useState, useEffect} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { v4 as uuidv4 } from 'uuid';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { io } from 'socket.io-client';

import Login from './screens/Login';
import Home from './screens/Home';
import Bot from './screens/Bot';
import Optimiser from './screens/Optimiser';
import Backtester from './screens/Backtester';
import Evolver from './screens/Evolver';
import Flash from './components/Flash';
import Header from './components/Header';
import Spinner from 'react-bootstrap/Spinner';

let endPoint = "http://localhost:5000";
let socket;



function App() {
  const [user, setUser] = useState();
  const [flashes, setFlashes] = useState([]);
  const [loadScreen, setLoadScreen] = useState(true);
  const [showLoadingMassage, setShowLoadingMassage] = useState(false);
  const [pairOptions, setPairOptions] = useState({});
  const [unsureSession, setUnsureSession] = useState(true);
  const [savedDataOptions, setSavedDataOptions] = useState({});

  const fetchData = () => {
    console.log("Trying to fetch data!")
    // if(pairOptions == {}){
      fetch('/api/fetch_markets', {
        method: 'GET', 
        headers: {'Content-Type': 'application/json'},
      }).then(res => res.json()).then(response => {
        setPairOptions(response['data']);
        if(savedDataOptions !== {}){
          setLoadScreen(false);
        }
      })
    // }
    // if(savedDataOptions == {}){
      fetch('/api/fetch_saved_data_info', {
        method: 'GET', 
        headers: {'Content-Type': 'application/json'},
      }).then(res => res.json()).then(response => {
        setSavedDataOptions(response['data']);
        if(pairOptions !== {}){
          setLoadScreen(false);
        }
      })
    // }
  }

  useEffect(() => {
    // Checks for saved user in local storage
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser && unsureSession) {
      const foundUser = JSON.parse(loggedInUser);
      fetch('/api/check_session', {
        method: 'POST', 
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({'email': foundUser['email']})
      }).then(res => res.json()).then(response => {
        if (response['flash']){
          displayFlashMessage(response);
        }
        if(!response['ok']){
          console.log('Not good user session.')
          handleLogout();
        } else {
          console.log('Good user session!')
          socketConnectUser(foundUser);
          setUser(foundUser);
          setUnsureSession(false);
        }
      });
    }
    if (loggedInUser){
      console.log("App.js: Trying to fetch data!")
      fetchData()
    } else {
      setLoadScreen(false);
    }
    setTimeout(() => {
      setShowLoadingMassage(true);
    }, 3000);
  }, []);

  // Saves user in local storage
  function onSuccesfulLogin(userData){
    localStorage.setItem('user', JSON.stringify(userData));
    socketConnectUser(userData);
    setUser(userData);
  }

  const socketConnectUser = (userData) => {
    socket = io.connect();
    socket.on("connect", msg => {
      socket.emit("join", {'user': userData, 'socket_id': socket.id})
    });
  }
  
  // Deletes user from local storage
  const handleLogout = () => {
    fetch('/auth/logout', {
      method: 'POST', 
      headers: {'Content-Type': 'application/json'},
    }).then(res => res.json()).then(response => {
      // setLoading(false);
      if(!response['ok']){
        console.log('Not good.')
      } else {
        console.log('All good.')
      }
    });
    setUser();
    localStorage.removeItem('user');
  };

  const displayFlashMessage = (response) => {
    let variant = response['flash']['type']
    if(response['flash']['type'] == 'error')
      variant = 'danger';
    setFlashes(flashes => flashes.concat({
      id: uuidv4(), 
      variant:variant, 
      message:response['msg']}));
  }

  const removeFlash = (flash) => {
    setFlashes(flashes => flashes.filter((item) => item.id !== flash.id));
  }
  
  return (
    <Router>
      <div className="App">
        {
          loadScreen 
          ? <div className="Flex-central-central" style={{height:"100vh"}}>
              <Spinner animation="border" />
              { showLoadingMassage &&
                 <p style={{margin:10}}> 
                   Taking a bit longer than usual, fetching some 
                   resources ... 
                </p>
              }
            </div>
          : 
          <>
            <Header user={user} onLogout={handleLogout}/>
            <div style={{justifyContent:'center', margin:10}}>
              {flashes.map((flash) => 
                <Flash key={flash.id} flash={flash} onClose={removeFlash} />)}
            </div>
            { 
              user === undefined
              ? <Login onSuccesfulLogin={onSuccesfulLogin}/>
              : 
              <Switch>
                <Route exact path="/dash/" render={() => (window.location = "http://dash.scx.holdings:5000/dash")} >
                  {/* <Redirect to="" />  */}
                </Route>

                <Route path="/bots/:botId">
                  <Bot 
                    setLoadScreen={setLoadScreen} 
                    displayFlashMessage={displayFlashMessage}/>
                </Route>
                <Route path="/evolver">
                  <Evolver socket={socket} 
                    displayFlashMessage={displayFlashMessage} 
                    fetchData={fetchData}
                    savedDataOptions={savedDataOptions}
                    pairOptions={pairOptions}/>
                </Route>
                <Route path="/backtester">
                  <Backtester socket={socket} 
                    displayFlashMessage={displayFlashMessage} 
                    fetchData={fetchData}
                    savedDataOptions={savedDataOptions}
                    pairOptions={pairOptions}/>
                </Route>
                <Route path="/optimiser">
                  <Optimiser socket={socket} 
                    displayFlashMessage={displayFlashMessage}  
                    fetchData={fetchData}
                    savedDataOptions={savedDataOptions}
                    pairOptions={pairOptions}/>
                </Route>
                <Route path="/">
                  <Home user={user} socket={socket} 
                    setLoadScreen={setLoadScreen} 
                    displayFlashMessage={displayFlashMessage}/>
                </Route>
              </Switch>
            }
          </>
        }
      </div>
    </Router>
  );
}

export default App;
