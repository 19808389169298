import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import '../App.css';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Plot from 'react-plotly.js';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { JSONEditor } from 'react-json-editor-viewer';

// TODO: This info should come from the API
import { init_optim_config, default_strategy_params, getTomorrowsDate,
  symbolSelectColourStyles, earliestDate, latestDate } from '../utils.js'
import OptimisationResults from '../components/OptimisationResults';
/// NEW LIBRARIES
import RangeSlider from 'react-bootstrap-range-slider';
import Select from 'react-select';

// TODO: This info should come from the API
let available_strategies = Object.keys(default_strategy_params);

function Optimiser(props) {
  const {socket, displayFlashMessage, fetchData, 
    savedDataOptions, pairOptions} = props;

  const [symbol, setSymbol] = useState('ETH/BUSD');
  const [timeframe, setTimeframe] = useState('15min');
  const [exchange, setExchange] = useState('binance-spot');
  const [strategy, setStrategy] = useState("RaduGoldOriginal");
  const [jobId, setJobId] = useState();
  const [figure, setFigure] = useState(null);  
  const [displayResults, setDisplayResults] = useState([]);
  const [waitingRunSimulation, setWaitingRunSimulation] = useState(false);
  const [progress, setProgress] = useState({
    current_progress: 0,
    total_progress: 0,
    last_time_per_simulation: 1000,
    average_time_per_simulation_last_100: 1000,
    average_time_per_simulation_last_10: 1000,
    average_time_per_simulation_all: 1000,
    message: ''
  });
  const [priceTrace, setPriceTrace] = useState();
  
  /* variables about date selection */
  const [minPossibleDate, setMinPossibleDate] = useState(new Date());
  const maxPossibleDate = getTomorrowsDate().toISOString().split("T")[0];
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState(maxPossibleDate);
  /* */ 
  let bt_config = init_optim_config;
  bt_config['strategy_params'] = default_strategy_params[strategy]
  const [btConfig, setBtConfig] = useState(bt_config);
  const [ttsplit, setTtsplit] = useState(70);
  const [symbolsSwitch, setSymbolsSwitch] = useState(false);
  const [availableSymbols, setAvailableSymbols] = useState();
  const history = useHistory();

  const addNewResults = (results) => {
    console.log('good_results_found')
    console.log(results)
    let base = results['base'];
    let toAdd = results['res'];
    // const newResults = [...displayResults];
    console.log("Before creating graphs, len is", displayResults.length)
    console.log("Need to add", toAdd.length)
    let trace1 = priceTrace;
    if(priceTrace === undefined || priceTrace == null){
        let base = results['base'];
        if(base !== null){
          console.log("Setting price trace to:")
          trace1 = {
            type: "scatter", mode: "lines", name: 'Price', 
            x: base.times, y: base.price, 
            line: {color: '#7f7f7f'}
          }
          console.log(trace1)
          setPriceTrace(trace1);
        }
    }
    toAdd.forEach(res =>
      {
        let idx_tt_split = /*res.figure.pnl.length * */ ttsplit / 100;
        var trace2 = {
          type: "scatter", mode: "lines", name: 'PnL',
          x: base.times, y: res.figure.pnl,
          yaxis:'y2',
          fill: 'tozeroy',
          line: {color: '#17BECF'}
        }

        var trace3 = {
          type: "scatter", mode: "lines", name: 'DD',
          x: base.times, y: res.figure.drawdown,
          yaxis:'y3',
          fill: 'tozeroy',
          line: {color: '#ff5555'}
        }

        var layout = { 
          title: 'Price vs. PnL vs. DD',
          shapes: [
            // {
            //     type: 'rect',
            //     xref: 'paper',
            //     yref: 'paper',
            //     x0: 0,
            //     x1: idx_tt_split,
            //     y0: 0,
            //     y1: 1,
            //     fillcolor: 'Gray',
            //     opacity: 0.2,
            //     layer: 'below',
            //     line: {
            //         width: 0
            //     }
            // },
            {
              type: 'rect',
              xref: 'paper',
              yref: 'paper',
              x0: idx_tt_split,
              x1: 1,
              y0: 0,
              y1: 1,
              fillcolor: 'Gray',
              opacity: 0.2,
              layer: 'below',
              line: {
                  width: 0
              }
          }
          ],
          yaxis: {
            // title: 'Price'
            ticks: '',
            showticklabels: false
          },
          yaxis2: {
            title: 'PnL',
            titlefont: {color: '#17BECF', fontWeight: 'bold'},
            tickfont: {color: '#17BECF', fontWeight: 'bold'},
            overlaying: 'y',
            side: 'right'
          },
          yaxis3: {
            title: 'DD',
            titlefont: {color: '#ff5555', fontWeight: 'bold'},
            tickfont: {color: '#ff5555', fontWeight: 'bold'},
            overlaying: 'y',
            range: [-1, 0],
            side: 'left'
          },
          xaxis: {'type': 'date'}
        };
        res['figure'] = {'data':[trace1, trace2, trace3], 'layout':layout} 
        // newResults.push(res);
        setDisplayResults((oldResults) => [...oldResults, res]);
    })
    console.log("After creating graphs, len is", displayResults.length)
    // return newResults
  }

  useEffect(() => {
    console.log("Optimiser.js: Trying to fetch data!")
    fetchData()
    if (availableSymbols === undefined){
      if(savedDataOptions[exchange] !== undefined) {
        updateToAvailableSymbols(exchange)
      } else {
        setTimeout(() => {
          if(availableSymbols === undefined || availableSymbols === null) {
            history.push('/')
          }
        }, 2000)
      }
    } else {
      setTimeout(() => {
        if(availableSymbols === undefined || availableSymbols === null) {
          history.push('/')
        }
      }, 2000)
    }
    socket.on("optimiser_update", msg => {
      if (msg.flash){
        displayFlashMessage(msg);
      }
      // Set Rq Job Id
      if (jobId == null && msg.job_id){
        setJobId(msg.job_id)
      }
      if (msg.results){
        try {
          let results = JSON.parse(msg.results);
          addNewResults(results);
        } catch(e){
          console.log(e)
          console.log(msg)
        }
      }

      if (msg.data === 'finished'){
        setWaitingRunSimulation(false);
        // setDisplayResults(msg.results);
      } else if (msg.data === 'halted_with_error'){
        setWaitingRunSimulation(false);
      } else if (msg.data === 'getting_candles_done'){
        setWaitingRunSimulation(true);
        let fg = JSON.parse(msg.fig)
        setFigure(fg);
      } else if (msg.data === 'progress_update'){
        setWaitingRunSimulation(true);
        if (msg.last_tps !== 0 && msg.last_tps !== undefined && msg.last_tps!== null) {
          setProgress({
            current_progress: msg.current_progress,
            total_progress: msg.total_progress,
            last_tps: Math.round(msg.last_tps * 1000) / 1000,
            avg_tps_10: Math.round(msg.avg_tps_10 * 1000) / 1000,
            avg_tps_100: Math.round(msg.avg_tps_100 * 1000) / 1000,
            avg_tps_all: Math.round(msg.avg_tps_all * 1000) / 1000,
            message: msg.msg
          })
        } else {
          setProgress({
            current_progress: msg.current_progress,
            total_progress: msg.total_progress,
            last_tps: 0,
            avg_tps_10: 0,
            avg_tps_100: 0,
            avg_tps_all: 0,
            message: msg.msg
          })
        }
      }
    });
  }, []);
  
  const onJsonChange = (key, value, parent, data) => {
    // console.log(data);
    setBtConfig(data)
  }

  const runSimulation = () => {
    // console.log(symbol.value)
    let body = {
      symbol: symbol.value, 
      exchange: exchange, 
      timeframe: timeframe, 
      bt_config: btConfig, 
      start_time: new Date(startTime).getTime(), 
      end_time: new Date(endTime).getTime(),
      socket_id: socket.id,
      train_test_split : parseFloat(ttsplit)/100,
      strategy: strategy
    }
    // console.log(body)
    setWaitingRunSimulation(true);
    fetch('/auth/run_optimiser', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }).then(res => res.json()).then(response => {
      if(response['flash']) {
        displayFlashMessage(response);
      } if (!response['ok']){
        setWaitingRunSimulation(false);
      }
    });
  };

  const stopSimulation = () => {
    if(window.confirm("Are you sure you want to force stop the optimiser?")){
      if(jobId!==undefined){
        fetch('/auth/stop_optimiser', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ job_id: jobId })
        }).then(res => res.json()).then(response => {
          if(response['flash']) {
            displayFlashMessage(response);
          } if (response['ok']){
            setWaitingRunSimulation(false);
          }
          });
      }
    }
  }

  const updateToAvailableSymbols = (exchange_id) => {
    if(exchange_id === 'binance-spot'){
      exchange_id = 'binance'
    }
    try {
      let symbolOptions = savedDataOptions[exchange_id];
      let newSymbols = Object.keys(symbolOptions).filter(symb => 
        symbolOptions[symb]['we_have'])
      setAvailableSymbols(newSymbols.map(s => { return {'label':s, 'value':s}}))
    } catch { }
  }

  const changeStrategy = (value) => {
    let bt_config = init_optim_config;
    bt_config['strategy_params'] = default_strategy_params[value];
    setStrategy(value);
    setBtConfig(bt_config);
  }

  const clickRow = (row) => {
    setFigure(row['figure']);
  }

  const loadParamsFurtherOptimisation = (row) => {
    console.log(row)
    let sol = row;
    let st = new Date(sol['start_time']) //.getTime()
    let et = new Date(sol['end_time'])  //.getTime()
    let symb = sol['symbol']
    let exch = sol['exchange']
    let tf = sol['timeframe']
    let bConf = Object.assign({}, btConfig)
    Object.keys(sol['strategy_params']).forEach((key) => {
      bConf['strategy_params'][key]['default'] = sol['strategy_params'][key]
    })
    console.log(bConf['strategy_params'])
    setStrategy(sol['strategy_name']);
    setExchange(exch);
    setSymbol({"value":symb, "label":symb});
    setTimeframe(tf);
    setStartTime(st.toJSON().slice(0,10));
    setEndTime(et.toJSON().slice(0,10));
    setBtConfig(bConf);
    window.scrollTo(0, 0)
  }

  const changeExchange = (event) => {
    if(!symbolsSwitch){
      updateToAvailableSymbols(event.target.value)
    } else {
      setAvailableSymbols(pairOptions[event.target.value])
    }
    setExchange(event.target.value);
    setSymbol(null); 
  }

  const selectSymbol = (data) => {
    let exchange_id = exchange;
    let symb = data['value'];
    if(exchange === 'binance-spot'){
      exchange_id = 'binance'
    }
    let current_min_date = startTime;
    try {
      let symbol_info = savedDataOptions[exchange_id][symb];
      let ts = symbol_info['exchange_info']['start_timestamp']
      if(ts > 0){
        current_min_date = new Date(ts).toISOString().split("T")[0]
        setMinPossibleDate(current_min_date)
      }
    } catch {}
    setSymbol(data);
    let new_start_time = latestDate(startTime, current_min_date);
    setStartTime(new_start_time);
  }

  const changeStartTime = (value) => {
    try {
      let strVal = new Date(value).toISOString().split("T")[0]
      let minDateStr = new Date(minPossibleDate).toISOString().split("T")[0]
      let newDate = latestDate(strVal, minDateStr);
      let maxDateStr = new Date(maxPossibleDate).toISOString().split("T")[0]
      newDate = earliestDate(newDate, maxDateStr);
      setStartTime(newDate)
    } catch {
      setStartTime(value)
    }
    
  }

  const changeEndTime = (value) => {
    try {
      let strVal = new Date(value).toISOString().split("T")[0]
      let minDateStr = new Date(minPossibleDate).toISOString().split("T")[0]
      let newDate = latestDate(strVal, minDateStr);
      let maxDateStr = new Date(maxPossibleDate).toISOString().split("T")[0]
      newDate = earliestDate(newDate, maxDateStr);
      setEndTime(newDate)
    } catch {
      setEndTime(value)
    }
  }

  const handleSymbolsSwitchChange = () => {
    if(symbolsSwitch){
      updateToAvailableSymbols(exchange)
    } else {
      setAvailableSymbols(pairOptions[exchange])
    }
    setSymbolsSwitch(!symbolsSwitch)
  }
  return (
      <Container style={{padding:10}}>
        <Form style={{margin:10}} className="Pretty-container">
            <Row>
              <Col style={{margin:10}}>
                {availableSymbols === undefined 
              ? <>
                  <Form.Label> If Symbol dropdown doesn't appear </Form.Label> 
                  <Form.Label> Go Home and return here </Form.Label>
                </>: 
                  <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{width:'30%'}}> 
                      <Form.Label>All</Form.Label>
                      <Form.Check 
                        style={{marginTop:8, marginLeft:5}}
                        id="symbols_switch" type="switch"
                        checked={symbolsSwitch}
                        onChange={() => handleSymbolsSwitchChange()}
                      />
                    </div>
                    <div style={{width:'100%'}}> 
                    { symbolsSwitch 
                      ? <Form.Label> All Symbols </Form.Label>
                      : <Form.Label> Loaded Symbols </Form.Label>}
                      <Select value={symbol}
                        styles={symbolSelectColourStyles(exchange, savedDataOptions)} 
                        options={availableSymbols} 
                        onChange={(value) =>{selectSymbol(value)}} />
                      <Form.Text className="text-muted">
                        {availableSymbols.length} symbols
                      </Form.Text>
                    </div>
                  </div>
                }
              </Col>
              <Col style={{margin:10}}>
                <Form.Label>Exchange</Form.Label>
                <Form.Control as="select" value={exchange} 
                  onChange={(event) => changeExchange(event)}>
                  <option value="bitstamp">Bitstamp</option>
                  <option value="binance-spot">Binance (Spot)</option>
                  <option value="binance-future">Binance (Futures)</option>
                  <option value="ftx">FTX</option>
                  <option value="coinbase" disabled>Coinbase</option>
                  <option value="kraken" disabled>Kraken</option>
                </Form.Control>
              </Col>
              <Col style={{margin:10}}>
                <Form.Label>Strategy</Form.Label>
                <Form.Control as="select" value={strategy} 
                  onChange={(event) =>{changeStrategy(event.target.value)}}>
                  {available_strategies.map((strat) => {
                    return <option key={strat} value={strat}>{strat}</option>
                  })}
                </Form.Control>
              </Col>
              </Row>
            <Row>
              <Col style={{margin:10}}>
                <Form.Label>Timeframe</Form.Label>
                <Form.Control as="select" value={timeframe} 
                  onChange={(event) =>{setTimeframe(event.target.value)}}>
                  {[1, 3, 5, 15, 30, 45, 75, 90, 135, 150].map(opt =>
                    <option key={opt+"min"} value={opt+"min"}>{opt} Min</option>)}
                  {[1, 2, 3, 4, 6, 8, 12].map(opt =>
                    <option key={opt+"h"} value={opt+"h"}>{opt} H</option>)}
                    <option value={'1D'}>1 Day</option>
                    <option value={'3D'}>3 Days</option>
                    <option value={'1W'}>1 Week</option>
                </Form.Control>
              </Col>
              <Col style={{margin:10}}>
                <Form.Label>Start Time</Form.Label>
                <Form.Control type="date"
                  min={minPossibleDate}
                  max={maxPossibleDate} 
                  value={startTime} 
                  onChange={(event) =>{changeStartTime(event.target.value)}} />
              </Col>
              <Col style={{margin:10}}>
                <Form.Label>End Time</Form.Label>
                <Form.Control type="date" 
                  min={minPossibleDate}
                  max={maxPossibleDate} 
                  value={endTime} 
                  onChange={(event) =>{changeEndTime(event.target.value)}} />
              </Col>
            </Row>
            <Row>
              <Col style={{margin:10}}>
                <Form.Label>Train / Test Split</Form.Label>
                <br/>
                <RangeSlider
                  value={ttsplit}
                  min={10}
                  max={95}
                  step={5}
                  onChange={e => setTtsplit(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{margin:10, marginTop:20}}>
                <Accordion>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Optimiser Config
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="Flex-central-central">
                        <JSONEditor
                            data={btConfig}
                            collapsible
                            onChange={onJsonChange} 
                            showRemoveButton={false}
                            showAddButton={false}
                            // view="dual"
                          />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
            </Row>
            <Row>
              <Col style={{margin:10}}>
                <Button variant="success" className="mb-2" onClick={runSimulation} disabled={waitingRunSimulation}>
                  {waitingRunSimulation && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />}
                  Run Simulation
                </Button>
                {waitingRunSimulation &&
                  <Button variant="danger" className="mb-2" onClick={stopSimulation}>    
                    Stop Simulation
                  </Button> 
                }
              </Col>
            </Row>
        </Form>
        { waitingRunSimulation ?
            <Row>
              <Col style={{margin:10}}>
                {progress.total_progress > 0
                ? <div className="Pretty-container">
                    <ProgressBar animated now={Math.round(100 * 100 * (progress.current_progress/progress.total_progress)) / 100} />
                    <hr/>
                    <Row>
                      <Col>
                        <p> Done / Total  </p>
                        <p style={{fontWeight:'bold'}}>{progress.current_progress} / {progress.total_progress} </p>
                      </Col>
                      <Col>
                        <p> Last Run Time </p>
                        <p style={{fontWeight:'bold'}}> {progress.last_tps}s </p>
                      </Col>
                      <Col>
                        <p> 10 Avg. TPS </p>
                        <p style={{fontWeight:'bold'}}> {progress.avg_tps_10}s </p>
                      </Col>
                      <Col>
                        <p> 100 Avg. TPS  </p>
                        <p style={{fontWeight:'bold'}}> {progress.avg_tps_100}s </p>
                      </Col>
                    </Row>
                    <hr/>
                    <Row>
                      <p style={{width:'100%'}}> {progress.message} </p>
                    </Row>
                  </div>
                : <div className="Pretty-container"> 
                    <Spinner  
                      variant="primary"
                      animation="border"
                      as="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"/>
                    <br/>
                    <p> {progress.message} </p> 
                  </div>
                }
              </Col>
            </Row>
          : null }
        <Row>
          <Col style={{margin:10}}>
            {(figure === null || figure === undefined)
            ? figure 
            : <div className="Pretty-container">
                <Plot data={figure['data']} layout={figure['layout']} style={{width:'100%'}}/>
              </div>}
          </Col>
        </Row>
        <Row>
          <Col style={{margin:10}}>
            <div className="Pretty-container">
              <OptimisationResults 
                data={displayResults} 
                clickRow={clickRow} 
                loadParams={loadParamsFurtherOptimisation}
                clearData={() => setDisplayResults([])}
                />
            </div>
          </Col>
        </Row>
      </Container>
  );
}

export default Optimiser;
