import logo from '../logo.png';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import {
  Link
} from "react-router-dom";

export default function Header(props){
  let {user, onLogout} = props;

  return (
    <Navbar bg="light" variant="light">
      {/* <Container> */}
        <Navbar.Brand href="#home">
          <img alt="" src={logo} width="40" height="40" className="d-inline-block" style={{marginLeft: 5, marginRight:15}}/>{' '}
          Dashboard
        </Navbar.Brand>
      {/* </Container> */}

      {user !== undefined && 
      <>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <Link to="/" style={{margin:10}}>Home</Link>
            <Link to="/backtester" style={{margin:10}}> Backtester </Link>
            <Link to="/optimiser" style={{margin:10}}> Optimiser </Link>
            <Link to="/evolver" style={{margin:10}}> Evolved Strats (BETA)</Link>
            <Link to="/bots" style={{margin:10}}>Bots</Link>
          </div>
        <Navbar.Collapse style={{backgroundColor:'transparent'}} className="justify-content-end">
          <Navbar.Text>
            User:<a href="#login">{user.email}</a>
          </Navbar.Text>
          <Nav.Link onClick={onLogout} style={{marginRight:0}}>
            <Navbar.Text> Logout </Navbar.Text>
          </Nav.Link>
          <Navbar.Brand href="#user">
            <img
              src={user.avatar}
              width="30"
              height="30"
              style={{marginRight:5}}
              className="d-inline-block align-top avatar-img"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
        </Navbar.Collapse>
      </>}
    </Navbar>
  )
}
