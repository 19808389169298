import React, { useState, useMemo, useCallback, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import DataTable from 'react-data-table-component';

import ReactJson from 'react-json-view';

import { usdFormatter, btcFormatter } from '../utils.js';

import styled from 'styled-components';

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
	&:hover {
		cursor: pointer;
	}
`;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;

// eslint-disable-next-line react/prop-types
const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<>
		<TextField
			id="search"
			type="text"
			placeholder="Filter by Symbol"
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
		/>
		<ClearButton type="button" onClick={onClear}>
			X
		</ClearButton>
	</>
);


const columns = [
  {
    name: 'Fitness',
    selector: row => Math.round(row['fitness'] * 1000) / 1000,
    sortable: true,
  },
  {
    name: 'PNL',
    selector: row => Math.round(row['pnl_ratio'] * 1000) / 1000,
    sortable: true,
    right: true,
  },
  {
    name: 'Symbol',
    selector: row => row['symbol'],
    sortable: true,
    right: true,
  },
  // {
  //   name: 'Profit Factor',
  //   selector: row => Math.round(row['profit_factor'] * 100) / 100,
  //   sortable: true,
  //   right: true,
  // },
  {
    name: 'Net Prof.',
    selector: row => usdFormatter.format(row['profit_net']),
    sortable: true,
    right: true,
  },
  {
    name: 'Length',
    selector: row => row['program_length'],
    sortable: true,
    right: true,
  },
  {
    name: 'Max DD',
    selector: row => Math.round(row['max_drawdown'] * 100) + "%",
    sortable: true,
    right: true,
  },
  {
    name: 'Longest DD',
    selector: row => Math.round(row['longest_drawdown_period'] * 100) + "%",
    sortable: true,
    right: true,
  },
  {
    name: 'Trades',
    selector: row => row['n_total_trades'],
    sortable: true,
    right: true,
  },
  // {
  //   name: 'Winrate',
  //   selector: row => Math.round(row['winrate'] * 100) + "%",
  //   sortable: true,
  //   right: true,
  // },
];


function EvolverResults(props) {

    let { data, clickRow, loadParams, displayFlashMessage } = props;
  
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const filteredItems = data.filter(
        item => ((item['symbol'] && item['symbol'].toLowerCase().includes(
            filterText.toLowerCase())) || 
            (item['filename'] && item['filename'].toLowerCase().includes(
              filterText.toLowerCase()))),
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent 
                onFilter={e => setFilterText(e.target.value)} 
                onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    const expandRow = (row) => {
        var clone = Object.assign({}, row['data']);
        if(clone['figure']) {
        delete clone['figure'];
        }
        let convert_to_$ = ['profit_net', 'balance', 'balance_initial', 'gross_loss', 
            'gross_profit', 'profit_avg_trade', 'total_fees_paid', 'profit_net_longs', 
            'profit_net_shorts', 'max_equity']
        convert_to_$.forEach(element => {
            if(Object.keys(clone).includes(element))
            clone[element] = usdFormatter.format(parseFloat(clone[element]))
        })
        let round_to_2 = ['profit_factor', 'pnl_ratio', 'winrate', 'pnl_ratio_buy_hold']
        round_to_2.forEach(element => {
            if(Object.keys(clone).includes(element))
            clone[element] = Math.round(parseFloat(clone[element]) * 100) / 100;
        })
        clone['max_drawdown'] = Math.round(clone['max_drawdown'] * 1000) / 10 + "%"
        
        return (
            <div style={{display:'flex', flexDirection:'column'}}>
                <div>
                    <Button key="load" onClick={() => loadParams(clone)} variant="primary">
                        Load For Further Optimisation
                    </Button>
                </div>
                <div style={{textAlign: 'left', fontSize:16,
                    display:'flex', alignSelf:'center', 
                    alignItems:'center', justifyContent:'center'}}> 
                    <ReactJson 
                        src={clone} 
                        name="results"
                        quotesOnKeys={false}
                        collapsed={1}
                        indentWidth={20}
                        collapseStringsAfterLength={18}
                        displayDataTypes={false}
                    />
                </div>
            </div>
        )
    }

    const clickOnRow = (row) => {
        displayFlashMessage({"msg":"Loaded", "flash":{"type":"success"}})
        loadParams(row)
    }


    return (<DataTable
        title={props.title}
        columns={columns}
        data={filteredItems}
        expandableRows
        expandableRowsComponent={expandRow}
        highlightOnHover
        paginationResetDefaultPage={resetPaginationToggle} 
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        Clicked
        selectableRows
        persistTableHead
        // contextActions={contextActions}
        // onSelectedRowsChange={handleRowSelected}
        // clearSelectedRows={toggleCleared}
        onRowClicked={clickOnRow}
        pagination
    />
    )
}

export default EvolverResults;
