import React, {useState, useEffect, useRef} from 'react';
import {useParams} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';

import RealTableTradeEntry from '../components/RealTableTradeEntry';

import Container from 'react-bootstrap/Container';
import Plot from 'react-plotly.js';
import '../App.css';


import BotRunInfo from '../components/BotRunInfo';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

// AllowUserToViewBotForm
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';

let status_colors = {
  'warning': 'gold',
  'success' : 'green',
  'fail' : 'indianred'
}


function AllowUserToViewBotForm(props) { 

  let { botId, displayFlashMessage } = props;

  const [userEmail, setUserEmail] = useState();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    if(window.confirm("Are you sure you want to send this to user?")){
      event.preventDefault();
      setLoading(true);
      fetch('/api/give_user_view_rights', {
        method: 'POST', 
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({bot_id: botId, user_email:userEmail})
      }).then(res => res.json()).then(response => {
        setLoading(false);
        if(response['flash']) {
          displayFlashMessage(response);
        }
      });
      setValidated(true);
    }
  };

  return (
    <Form as={Col} validated={validated} 
      style={{margin:10, display:'flex', flexDirection:'row', justifyContent:'center'}}>
        <InputGroup>
          <InputGroup.Text>User Email</InputGroup.Text>
          <Form.Control required 
            value={userEmail}
            type="email" 
            placeholder="Enter email"
            onChange={(event) => setUserEmail(event.target.value)}/>
          <Button variant="primary" type="submit" 
            onClick={(event) => handleSubmit(event)} disabled={loading}>
            {loading && <Spinner as="span" animation="grow" size="sm"
                  role="status" aria-hidden="true" />}
            Give View Rights
          </Button>
        </InputGroup>
    </Form>
  )
}


const BotRuns = (props) => {
  const {bot} = props;
  if (bot.runs.length > 0)
    return (
      <div style={{display:'flex', flex:12, justifyContent:'center', flexDirection:'column', margin:20}}>
        {/* <Plot data={JSON.parse(gnatt)['data']} layout={JSON.parse(gnatt)['layout']} style={{}}/> */}
        <p style={{marginBottom:5, color:'gray'}}> Last {bot.runs.slice(-50).length} Bot Runs </p>
        <div style={{display:'flex', flexDirection:'row', margin:10, justifyContent:'center'}}>
          {bot.runs.slice(-50).map(run => {
            run['status'] = 'fail'
            if (!run['succesful_run']){
              run['status'] = 'fail'
            } else {
              run['status'] = 'success'
              for(let i = 0; i < run.logs.length; i++){
                let log = run.logs[i];
                if(log.type === 'warning'){
                  run['status'] = 'warning'
                  break
                }
              }
            }
            return (
              <OverlayTrigger
                key={run.id} 
                placement="top"
                delay={{ show: 20, hide: 50 }}
                overlay={BotRunInfo(run)}
              >
                <div 
                  onClick={() => run.logs.forEach(log => console.log(log.msg))}
                  style={{
                    height:25, width:14, borderRadius:2,
                    margin:2, backgroundColor:status_colors[run['status']]
                  }}/> 
              </OverlayTrigger>
              )
          })}
        </div>
        <div style={{display:'flex', flexDirection:'column', justifyContent: 'space-between'}}>
          <p style={{marginBottom:5, color:'gray', fontSize:14, marginRight:15, marginLeft:15}}> 
            {new Date(bot.runs.slice(-50)[0].start_timestamp * 1000).toDateString()} &nbsp; {' -> '}  
          </p>
          <p style={{marginBottom:5, color:'gray', fontSize:14, marginRight:15, marginLeft:15}}> 
            {' <- '} &nbsp; { new Date(bot.runs.slice(-1)[0].start_timestamp * 1000).toDateString()} 
          </p>
        </div>
      </div>
      )
}


function Bot(props){
  let { botId } = useParams();
  const {socket, displayFlashMessage, setLoadScreen} = props;
  const [bot, setBot] = useState();
  const [figure, setFigure] = useState();

  useEffect(() => {
    if (bot === undefined){
      // setLoadScreen(true);
      fetch('/auth/fetch_bot', {
          method: 'POST', 
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({bot_id: botId})
        }).then(res => res.json()).then(response => {
          // console.log("Got BOT response!")
          // console.log(response)
          if(!response['ok']){
            console.log('nok');
          } else {
            console.log('ok');
          }
          if (response['flash']){
            displayFlashMessage(response)
          }
          setBot(response['data'])
          setFigure(JSON.parse(response['data']['fig']))
          setLoadScreen(false);
        });
    } else {
      setLoadScreen(false);
    }
  });

  const parentRef = useRef();

  return (
      bot === undefined ?
      <div className="Flex-central-central">
        <h3>{botId}</h3>
      </div>
      : <Container style={{padding:10}}>
          <h3>{bot['name']}</h3>
          <div className="Flex-central-central">
            <div style={{display:'flex'}}>
              
            </div>    
            <Col>
                {figure == null 
                ? figure 
                : <div key={"plotParent"} ref={parentRef} className="Pretty-container">
                    <Plot 
                      data={figure['data']} 
                      layout={figure['layout']} 
                      // config={{'doubleClick': true}} 
                      style={{width:'100%'}}/>
                </div>}
            </Col>
            <Col>
          <div className="Pretty-container">
            <Tabs defaultActiveKey="trades" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="trades" title="Trades">
                <div style={{overflow: 'scroll', height:'40vh'}}>
                  <Table striped bordered hover>
                    <thead> 
                      <tr> 
                        <th>#</th> 
                        <th>Type</th> 
                        <th>Time</th> 
                        <th>Status</th> 
                        <th>Price</th> 
                        <th>Orig / Executed Qty</th> 
                        <th>Profit</th> 
                      </tr>
                    </thead>
                    {bot.trades.map(trade => { 
                      return <RealTableTradeEntry key={trade.id} trade={trade}/>
                      })}
                  </Table>
                </div>
              </Tab>
              <Tab eventKey="runs" title="Runs and Logs">
                <hr/>
                <BotRuns bot={bot} />
              </Tab>
              <Tab eventKey="pnl" title="PNL">
                {/* <Plot data={figure.pnl_fig['data']} layout={figure.pnl_fig['layout']} style={{width:'100%'}}/> */}
              </Tab>
              {/* <Tab eventKey="dd" title="Drawdown">
                <Plot data={figure.dd_fig['data']} layout={figure.dd_fig['layout']} style={{width:'100%'}}/>
              </Tab>
              <Tab eventKey="indicators" title="Indicators">
                <Plot data={figure.indicators_fig['data']} layout={figure.indicators_fig['layout']} style={{width:'100%'}}/>
              </Tab> */}
              <Tab eventKey="equity" title="Equity">
                {/* <Plot data={figure.equity_fig['data']} layout={figure.equity_fig['layout']} style={{width:'100%'}}/> */}
              </Tab>
              <Tab eventKey="more" title="More">
                {/* <Plot data={figure.equity_fig['data']} layout={figure.equity_fig['layout']} style={{width:'100%'}}/> */}
                <AllowUserToViewBotForm 
                  botId={botId} 
                  displayFlashMessage={displayFlashMessage}/>
              </Tab>
            </Tabs>
          </div>
          </Col>
        </div>
      </Container>
  );
}

export default Bot;