import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import '../App.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
// TODO: This info should come from the API
import { init_evolver_config, getTomorrowsDate,
  symbolSelectColourStyles, earliestDate, latestDate } from '../utils.js'
import EvolverResults from '../components/EvolverResults';
import BacktesterResults from '../components/BacktesterResults';
/// NEW LIBRARIES
import Select from 'react-select';

function Evolver(props) {
  const {socket, displayFlashMessage, fetchData, 
    savedDataOptions, pairOptions} = props;

  const [symbol, setSymbol] = useState('ETH/BUSD');
  const [strategy, setStrategy] = useState();
  const [timeframe, setTimeframe] = useState('15min');
  const [exchange, setExchange] = useState('binance-spot');
  const [availableStrategies, setAvailableStrategies] = useState([]);
  const [loading, setLoading] = useState(false);
  /* variables about date selection */
  const [minPossibleDate, setMinPossibleDate] = useState(new Date());
  const maxPossibleDate = getTomorrowsDate().toISOString().split("T")[0];
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState(maxPossibleDate);
  /* */ 
  let bt_config = init_evolver_config;
  const [btConfig, setBtConfig] = useState(bt_config);
  const [symbolsSwitch, setSymbolsSwitch] = useState(false);
  const [availableSymbols, setAvailableSymbols] = useState();

  const [results, setResults] = useState(null);
  const [figure, setFigure] = useState(null);  
  const history = useHistory();

  useEffect(() => {
    console.log("Evolver.js: Trying to fetch data!")
    fetchData()
    if (availableSymbols === undefined){
      if(savedDataOptions[exchange] !== undefined) {
        updateToAvailableSymbols(exchange)
      } else {
        setTimeout(() => {
          if(availableSymbols === undefined || availableSymbols === null) {
            history.push('/')
          }
        }, 2000)
      }
    } else {
      setTimeout(() => {
        if(availableSymbols === undefined || availableSymbols === null) {
          history.push('/')
        }
      }, 2000)
    }

    fetch('/api/get_evolved_strats', {
      method: 'GET', headers: {'Content-Type': 'application/json'}
    }).then(res => res.json()).then(response => {
      console.log("Response from evolved strats")
      console.log(response)
      if(response['flash']) {
        displayFlashMessage(response);
      } if (!response['ok']){
        
      }
      setAvailableStrategies(response['data']);
    });

    socket.on("backtester_update", msg => {
      if (msg.flash){
        displayFlashMessage(msg);
      }
      // console.log("Got backtester update!")
      // console.log(msg)
      // console.log("Got backtester update! ^^^")
      if(msg.price_fig){
        // console.log("Got price fig!")
        setFigure({
          indicators_fig:JSON.parse(msg.indicators_fig),
          equity_fig:JSON.parse(msg.equity_fig),
          price_fig:JSON.parse(msg.price_fig),
          pnl_fig:JSON.parse(msg.pnl_fig),
          dd_fig:JSON.parse(msg.dd_fig),
          trades: msg.trades,
        });
      }
      else if (msg.fig){
        setFigure(JSON.parse(msg.fig));
      }
      if (msg.results){
        let r = msg.results;
        // let convert_to_$ = ['profit_net', 'balance', 'balance_initial', 
        //   'gross_loss', 'gross_profit', 'profit_avg_trade', 'total_fees_paid', 
        //   'profit_net_longs', 'profit_net_shorts', 'max_equity']
        // convert_to_$.forEach(element => {
        //   r[element] = usdFormatter.format(parseFloat(r[element]))
        // })
        // let round_to_2 = ['profit_factor', 'pnl_ratio', 
        //   'winrate', 'pnl_ratio_buy_hold']
        // round_to_2.forEach(element => {
        //   r[element] = Math.round(parseFloat(r[element]) * 100) / 100;
        // })
        r['max_drawdown'] = Math.round(r['max_drawdown'] * 1000) / 10 + "%"
        setResults(r);
      }

      if (msg.data === 'finished'){
        setLoading(false);
      } else if (msg.data === 'halted_with_error'){
        setLoading(false);
      } 
    });

  }, []);
  
  const runBacktest = () => {
    // console.log(symbol.value)
    let body = {
      symbol: symbol.value, 
      exchange: exchange, 
      timeframe: timeframe, 
      bt_config: btConfig, 
      start_time: new Date(startTime).getTime(), 
      end_time: new Date(endTime).getTime(),
      socket_id: socket.id,
      // train_test_split : parseFloat(ttsplit)/100,
      // strategy: strategy
    }
    // console.log(body)
    setLoading(true);
    fetch('/api/backtest', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }).then(res => res.json()).then(response => {
      if(response['flash']) {
        displayFlashMessage(response);
      } if (!response['ok']){
        setLoading(false);
      }
    });
  };

  const updateToAvailableSymbols = (exchange_id) => {
    if(exchange_id === 'binance-spot'){
      exchange_id = 'binance'
    }
    try {
      let symbolOptions = savedDataOptions[exchange_id];
      let newSymbols = Object.keys(symbolOptions).filter(symb => 
        symbolOptions[symb]['we_have'])
      setAvailableSymbols(newSymbols.map(s => { return {'label':s, 'value':s}}))
    } catch { }
  }

  const clickRow = (row) => {
    // setFigure(row['figure']);
  }

  const changeExchange = (event) => {
    if(!symbolsSwitch){
      updateToAvailableSymbols(event.target.value)
    } else {
      setAvailableSymbols(pairOptions[event.target.value])
    }
    setExchange(event.target.value);
    setSymbol(null); 
  }

  const selectSymbol = (data) => {
    let exchange_id = exchange;
    let symb = data['value'];
    if(exchange === 'binance-spot'){
      exchange_id = 'binance'
    }
    let current_min_date = startTime;
    try {
      let symbol_info = savedDataOptions[exchange_id][symb];
      let ts = symbol_info['exchange_info']['start_timestamp']
      if(ts > 0){
        current_min_date = new Date(ts).toISOString().split("T")[0]
        setMinPossibleDate(current_min_date)
      }
    } catch {}
    setSymbol(data);
    let new_start_time = latestDate(startTime, current_min_date);
    setStartTime(new_start_time);
  }

  const changeStartTime = (value) => {
    try {
      let strVal = new Date(value).toISOString().split("T")[0]
      let minDateStr = new Date(minPossibleDate).toISOString().split("T")[0]
      let newDate = latestDate(strVal, minDateStr);
      let maxDateStr = new Date(maxPossibleDate).toISOString().split("T")[0]
      newDate = earliestDate(newDate, maxDateStr);
      setStartTime(newDate)
    } catch {
      setStartTime(value)
    }
    
  }

  const changeEndTime = (value) => {
    try {
      let strVal = new Date(value).toISOString().split("T")[0]
      let minDateStr = new Date(minPossibleDate).toISOString().split("T")[0]
      let newDate = latestDate(strVal, minDateStr);
      let maxDateStr = new Date(maxPossibleDate).toISOString().split("T")[0]
      newDate = earliestDate(newDate, maxDateStr);
      setEndTime(newDate)
    } catch {
      setEndTime(value)
    }
  }

  const changeStrategy = (value) => {
    let bt_config = btConfig;
    bt_config['strategy_filename'] = value;
    setStrategy(value);
    setBtConfig(bt_config);
  }

  const handleSymbolsSwitchChange = () => {
    if(symbolsSwitch){
      updateToAvailableSymbols(exchange)
    } else {
      setAvailableSymbols(pairOptions[exchange])
    }
    setSymbolsSwitch(!symbolsSwitch)
  }

  const loadParamsForBacktest = (row) => {
    // console.log(row)
    let sol = row;
    let st = new Date(sol['start_time']) //.getTime()
    let et = new Date(sol['end_time'])  //.getTime()
    let symb = sol['symbol']
    let exch = sol['exchange_name']
    if(exch === 'binance'){
      exch = 'binance-spot'
    }
    let tf = sol['timeframe']
    let bConf = Object.assign({}, btConfig)
    bConf['strategy_filename'] = sol['filename']
    bConf['trained_from'] = sol['start_time']
    bConf['trained_until'] = sol['end_time']
    // console.log(bConf)
    setExchange(exch);
    setStrategy(sol['filename']);
    setSymbol({"value":symb, "label":symb});
    setTimeframe(tf);
    setStartTime(st.toJSON().slice(0,10));
    setEndTime(et.toJSON().slice(0,10));
    setBtConfig(bConf);
    window.scrollTo(0, 0)
  }

  return (
      <Container style={{padding:10}}>
        <Form style={{margin:10}} className="Pretty-container">
            <Row>
              <Col style={{margin:10}}>
                {availableSymbols === undefined 
              ? <>
                  <Form.Label> If Symbol dropdown doesn't appear </Form.Label> 
                  <Form.Label> Go Home and return here </Form.Label>
                </>: 
                  <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{width:'30%'}}> 
                      <Form.Label>All</Form.Label>
                      <Form.Check 
                        style={{marginTop:8, marginLeft:5}}
                        id="symbols_switch" type="switch"
                        checked={symbolsSwitch}
                        onChange={() => handleSymbolsSwitchChange()}
                      />
                    </div>
                    <div style={{width:'100%'}}> 
                    { symbolsSwitch  
                      ? <Form.Label> All Symbols </Form.Label>
                      : <Form.Label> Loaded Symbols </Form.Label>}
                      <Select value={symbol}
                        styles={symbolSelectColourStyles(exchange, savedDataOptions)} 
                        options={availableSymbols} 
                        onChange={(value) =>{selectSymbol(value)}} />
                      <Form.Text className="text-muted">
                        {availableSymbols.length} symbols
                      </Form.Text>
                    </div>
                  </div>
                }
              </Col>
              <Col style={{margin:10}}>
                <Form.Label>Exchange</Form.Label>
                <Form.Control as="select" value={exchange} 
                  onChange={(event) => changeExchange(event)}>
                  <option value="bitstamp">Bitstamp</option>
                  <option value="binance-spot">Binance (Spot)</option>
                  <option value="binance-future">Binance (Futures)</option>
                  <option value="ftx">FTX</option>
                  <option value="coinbase" disabled>Coinbase</option>
                  <option value="kraken" disabled>Kraken</option>
                </Form.Control>
              </Col>
              <Col style={{margin:10}}>
                <Form.Label>Strategy</Form.Label>
                <Form.Control as="select" value={strategy} 
                  onChange={(event) =>{changeStrategy(event.target.value)}}>
                  {availableStrategies.map((strat) => {
                    return <option key={strat['filename']} value={strat['filename']}>{strat['filename']}</option>
                  })}
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col style={{margin:10}}>
                <Form.Label>Timeframe</Form.Label>
                <Form.Control as="select" value={timeframe} 
                  onChange={(event) =>{setTimeframe(event.target.value)}}>
                  {[1, 3, 5, 15, 30, 45, 75, 90, 135, 150].map(opt =>
                    <option key={opt+"min"} value={opt+"min"}>{opt} Min</option>)}
                  {[1, 2, 3, 4, 6, 8, 12].map(opt =>
                    <option key={opt+"h"} value={opt+"h"}>{opt} H</option>)}
                    <option value={'1D'}>1 Day</option>
                    <option value={'3D'}>3 Days</option>
                    <option value={'1W'}>1 Week</option>
                </Form.Control>
              </Col>
              <Col style={{margin:10}}>
                <Form.Label>Start Time</Form.Label>
                <Form.Control type="date"
                  min={minPossibleDate}
                  max={maxPossibleDate} 
                  value={startTime} 
                  onChange={(event) =>{changeStartTime(event.target.value)}} />
              </Col>
              <Col style={{margin:10}}>
                <Form.Label>End Time</Form.Label>
                <Form.Control type="date" 
                  min={minPossibleDate}
                  max={maxPossibleDate} 
                  value={endTime} 
                  onChange={(event) =>{changeEndTime(event.target.value)}} />
              </Col>
            </Row>
            <Row>
              <Col style={{margin:10}}>
                <Button variant="success" className="mb-2" onClick={runBacktest} disabled={loading}>
                  {loading && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />}
                  Backtest
                </Button>
              </Col>
            </Row>
        </Form>
        <Row>
          <BacktesterResults figure={figure} results={results} />
        </Row>
        <Row>
          <Col style={{margin:10}}>
            <div className="Pretty-container">
              <EvolverResults 
                title="Evolved Strategies"
                loadParams={loadParamsForBacktest}
                data={availableStrategies} 
                displayFlashMessage={displayFlashMessage}
                clickRow={clickRow} />
            </div>
          </Col>
        </Row>
      </Container>
  );
}

export default Evolver;
