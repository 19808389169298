import React, {useEffect, useState} from 'react';
import Alert from 'react-bootstrap/Alert';
import ProgressBar from 'react-bootstrap/ProgressBar';
// import Button from 'react-bootstrap/Button';

export default function Flash(props) {
  const {flash, onClose} = props;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if(flash.variant !== 'danger')
      setTimeout(() => {onClose(flash)}, 7000)
    // const interval = setInterval(() => {
    //   setProgress(progress => progress + 30)
    // }, 1000);
    // return () => clearInterval(interval);
  }, []);

    return (
      <div>
        <Alert 
          style={{marginBottom:0}}
          variant={flash.variant} 
          onClose={() => onClose(flash)} dismissible>
          {flash.message}
        </Alert>
        {/* <ProgressBar 
          style={{height:5, margin: 15, marginTop:-10, position:'relative'}} 
            variant={flash.variant} now={progress} /> */}
      </div>
    );
}

