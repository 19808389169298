import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DataTable from 'react-data-table-component';
import ReactJson from 'react-json-view';

import { usdFormatter, btcFormatter } from '../utils.js'


const columns = [
  {
    name: 'Name',
    selector: row => row['strategy_name'],
    sortable: true,
  },
  {
    name: 'Symbol',
    selector: row => row['symbol'],
    sortable: true,
    right: true,
  },
  {
    name: 'TF',
    selector: row => row['timeframe'],
    sortable: true,
    right: true,
  },
  {
    name: 'PNL',
    selector: row => Math.round(row['pnl_ratio'] * 1000) / 1000,
    sortable: true,
    right: true,
  },
  {
    name: 'PF',
    selector: row => Math.round(row['profit_factor'] * 100) / 100,
    sortable: true,
    right: true,
  },
  {
    name: 'Net P',
    selector: row => usdFormatter.format(row['profit_net']),
    sortable: true,
    right: true,
  },
  {
    name: 'Max DD',
    selector: row => Math.round(row['max_drawdown'] * 100) + "%",
    sortable: true,
    right: true,
  },
  {
    name: 'Trades',
    selector: row => row['n_total_trades'],
    sortable: true,
    right: true,
  },
  {
    name: 'Winrate',
    selector: row => Math.round(row['winrate'] * 100) + "%",
    sortable: true,
    right: true,
  },
];

function SendStratToUserForm(props) { 

  let { strategy, displayFlashMessage } = props;

  const [userEmail, setUserEmail] = useState();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (strat_id, event) => {
    if(window.confirm("Are you sure you want to send this to user?")){
      event.preventDefault();
      setLoading(true);
      fetch('/auth/send_saved_strat', {
        method: 'POST', 
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({strat_id: strat_id, user_email:userEmail})
      }).then(res => res.json()).then(response => {
        setLoading(false);
        if(response['flash']) {
          displayFlashMessage(response);
        }
      });
      setValidated(true);
    }
  };

  return (
    <Form as={Col} validated={validated} 
      style={{margin:10, display:'flex', flexDirection:'row', justifyContent:'center'}}>
        <InputGroup>
          <InputGroup.Text>User Email</InputGroup.Text>
          <Form.Control required 
            value={userEmail}
            type="email" 
            placeholder="Enter email"
            onChange={(event) => setUserEmail(event.target.value)}/>
          <Button variant="primary" type="submit" 
            onClick={(event) => handleSubmit(strategy['id'], event)} disabled={loading}>
            {loading && <Spinner as="span" animation="grow" size="sm"
                  role="status" aria-hidden="true" />}
            Send This Strategy to User
          </Button>
        </InputGroup>
    </Form>
  )

}


function SavedStrategyParams(props) {

    let { data, clickRow, runBacktest, displayFlashMessage } = props;

    const [strats, setStrats] = useState([]);

    useEffect(() => {
      if(strats.length == 0){
        setStrats(data)
      }
    }, [])

    const removeStrat = (strat_id) => { 
      // console.log(strat_id)
      if(window.confirm("Are you sure you want to remove this saved strategy?")){
        fetch('/auth/delete_saved_strat', {
          method: 'POST', 
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({strat_id: strat_id})
        }).then(res => res.json()).then(response => {
          if(!response['ok']){
            console.log('nok')
          } else {
            console.log('ok')
            let new_strats = strats.filter(strat => strat['id'] !== strat_id)
            setStrats(new_strats)
          }
          if (response['flash']){
            displayFlashMessage(response)
          }

        });
      }
    }


    const expandRow = (row) => {
      var clone = Object.assign({}, row['data']);
      delete clone['figure'];
      let r = clone;
      let convert_to_$ = ['profit_net', 'balance', 'balance_initial', 'gross_loss', 
          'gross_profit', 'profit_avg_trade', 'total_fees_paid', 'profit_net_longs', 
          'profit_net_shorts', 'max_equity']
        convert_to_$.forEach(element => {
          r[element] = usdFormatter.format(parseFloat(r[element]))
        })
        let round_to_2 = ['profit_factor', 'pnl_ratio', 'winrate', 'pnl_ratio_buy_hold']
        round_to_2.forEach(element => {
          r[element] = Math.round(parseFloat(r[element]) * 100) / 100;
        })
        r['max_drawdown'] = Math.round(r['max_drawdown'] * 1000) / 10 + "%"
      return <Container>
        <Row style={{display:'flex', flexDirection:'column'}}>
          <SendStratToUserForm 
            strategy={clone} 
            displayFlashMessage={displayFlashMessage}/>
          <Col style={{margin:10}}>
            <Button key="delete" onClick={()=>removeStrat(clone['id'])} variant="danger">
              Delete
            </Button>
            <Button key="backtest" onClick={()=>runBacktest(clone)} variant="success">
              Load Params
            </Button>
          </Col>
        </Row>
        <Row>
          <Col style={{ margin:10}}>
            <div style={{textAlign: 'left', fontSize:16,
              display:'flex', alignSelf:'center', 
              alignItems:'center', justifyContent:'center'}}> 
              <ReactJson 
                src={r} 
                name="results"
                quotesOnKeys={false}
                collapsed={1}
                indentWidth={20}
                collapseStringsAfterLength={18}
                displayDataTypes={false}
                />
              </div>
          </Col>
        </Row>
      </Container>
    }
  
  return (<DataTable
    title="Saved Strategies"
    columns={columns}
    data={strats}
    expandableRows
    expandableRowsComponent={expandRow}
    highlightOnHover
    Clicked
    onRowClicked={clickRow}
    pagination
  />
  )
}

export default SavedStrategyParams;
