import React, { useState, useMemo, useCallback, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import DataTable from 'react-data-table-component';

import ReactJson from 'react-json-view';

import { usdFormatter, btcFormatter } from '../utils.js';


const columns = [
  {
    name: 'Fitness',
    selector: row => Math.round(row['fitness'] * 1000) / 1000,
    sortable: true,
  },
  {
    name: 'PNL',
    selector: row => Math.round(row['pnl_ratio'] * 1000) / 1000,
    sortable: true,
    right: true,
  },
  {
    name: 'Profit Factor',
    selector: row => Math.round(row['profit_factor'] * 100) / 100,
    sortable: true,
    right: true,
  },
  {
    name: 'Net Prof.',
    selector: row => usdFormatter.format(row['profit_net']),
    sortable: true,
    right: true,
  },
  {
    name: 'Max DD',
    selector: row => Math.round(row['max_drawdown'] * 100) + "%",
    sortable: true,
    right: true,
  },
  {
    name: 'Longest DD',
    selector: row => Math.round(row['longest_drawdown_period'] * 100) + "%",
    sortable: true,
    right: true,
  },
  {
    name: 'Trades',
    selector: row => row['n_total_trades'],
    sortable: true,
    right: true,
  },
  {
    name: 'Winrate',
    selector: row => Math.round(row['winrate'] * 100) + "%",
    sortable: true,
    right: true,
  },
];


function OptimisationResults(props) {

  let { data, clickRow, loadParams, clearData } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  // const [displayData, setDisplayData] = useState();
  const [toggleCleared, setToggleCleared] = useState(false);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleSave = () => {
      // if (window.confirm(`Are you sure you want to save the selected results?`)) {
      setToggleCleared(!toggleCleared);
      // make call to backend to store these in a database
      let results = selectedRows.map((res) => {
        console.log('SAVING...')
        if(res['figure']) {
          delete res['figure'];
        }
        console.log(res)
        delete res['trade_amount']
        delete res['longest_drawdown_period']
        delete res['average_drawdown_period']
        delete res['fitness']
        return res;
      })
      fetch('/auth/save_results', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ results: results })
      }).then(res => res.json()).then(response => {
        // setLoading(false);
        if (!response['ok']) {
          console.log('Not good.')
        } else {
          console.log('All good.')
        }
      });
    };

    const handleClear = () => {
      if (window.confirm(`Are you sure you want to clear all results? You can't undo this.`)) {
        setToggleCleared(!toggleCleared);
        clearData();
      }
    };
    return (
      <>
      <Button key="save" onClick={handleSave} variant="success">
        Save Solutions
      </Button>
      <Button key="clear" onClick={handleClear} variant="danger">
        Clear All
      </Button>
      </>
    );
  }, [data, selectedRows, toggleCleared]);

  const expandRow = (row) => {
    var clone = Object.assign({}, row['data']);
    if(clone['figure']) {
      delete clone['figure'];
    }
    let convert_to_$ = ['profit_net', 'balance', 'balance_initial', 'gross_loss', 
        'gross_profit', 'profit_avg_trade', 'total_fees_paid', 'profit_net_longs', 
        'profit_net_shorts', 'max_equity']
      convert_to_$.forEach(element => {
        if(Object.keys(clone).includes(element))
          clone[element] = usdFormatter.format(parseFloat(clone[element]))
      })
      let round_to_2 = ['profit_factor', 'pnl_ratio', 'winrate', 'pnl_ratio_buy_hold']
      round_to_2.forEach(element => {
        if(Object.keys(clone).includes(element))
          clone[element] = Math.round(parseFloat(clone[element]) * 100) / 100;
      })
      clone['max_drawdown'] = Math.round(clone['max_drawdown'] * 1000) / 10 + "%"
    
      return (
        <div style={{display:'flex', flexDirection:'column'}}>
          <div>
          <Button key="load" onClick={() => loadParams(clone)} variant="primary">
            Load For Further Optimisation
          </Button>
          </div>
          <div style={{textAlign: 'left', fontSize:16,
            display:'flex', alignSelf:'center', 
            alignItems:'center', justifyContent:'center'}}> 
            <ReactJson 
              src={clone} 
              name="results"
              quotesOnKeys={false}
              collapsed={1}
              indentWidth={20}
              collapseStringsAfterLength={18}
              displayDataTypes={false}
              />
          </div>
        </div>
    )
  }


  return (<DataTable
    title="Optimiser Results"
    columns={columns}
    data={data}
    expandableRows
    expandableRowsComponent={expandRow}
    highlightOnHover
    Clicked
    selectableRows
    contextActions={contextActions}
    onSelectedRowsChange={handleRowSelected}
    clearSelectedRows={toggleCleared}
    onRowClicked={clickRow}
    pagination
  />
  )
}

export default OptimisationResults;
