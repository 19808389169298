import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { JSONEditor } from 'react-json-editor-viewer';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Plot from 'react-plotly.js';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import ReactJson from 'react-json-view';
import { init_bt_config, default_strategy_params, getTomorrowsDate,
  dot, symbolSelectColourStyles, latestDate, earliestDate,
  usdFormatter, btcFormatter } from '../utils.js'
import SavedStrategyParams from '../components/SavedStrategyParams';
import TableTradeEntry from '../components/TableTradeEntry';
import BacktesterResults from '../components/BacktesterResults';
import Select from 'react-select';

let available_strategies = Object.keys(default_strategy_params);

const Backtester = (props) => {
  const {socket, displayFlashMessage, fetchData, 
    savedDataOptions, pairOptions} = props;

  const [btConfig, setBtConfig] = useState(init_bt_config);
  const [symbol, setSymbol] = useState("ETH/BUSD");
  // const [symbolSearch, setSymbolSearch] = useState();
  const [amountSwitch, setAmountSwitch] = useState(false);
  const [stopLossSwitch, setStopLossSwitch] = useState(false);
  const [takeProfitSwitch, setTakeProfitSwitch] = useState(false);
  const [reinvestProfitsSwitch, setReinvestProfitsSwitch] = useState(false);
  const [strategy, setStrategy] = useState("RaduGoldOriginal");
  const [timeframe, setTimeframe] = useState("15min");
  const [loading, setLoading] = useState(false);
  const [exchange, setExchange] = useState('binance-spot');
  const [backtester, setBacktester] = useState('v2');
  const [figure, setFigure] = useState(null);
  const [results, setResults] = useState(null);
  const [savedStrats, setSavedStrats] = useState([]);

  /* variables about date selection */
  const [minPossibleDate, setMinPossibleDate] = useState(new Date());
  const maxPossibleDate = getTomorrowsDate().toISOString().split("T")[0];
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState(maxPossibleDate);
  const [symbolsSwitch, setSymbolsSwitch] = useState(false);
  const [availableSymbols, setAvailableSymbols] = useState();
  const history = useHistory();


  useEffect(() => {
    // console.log("Fetching saved strats")
    console.log("Backtester.js: Trying to fetch data!")
    fetchData()
    if(availableSymbols == undefined || availableSymbols == null){
      if(savedDataOptions[exchange] !== undefined) {
        updateToAvailableSymbols(exchange)
      } else {
        setTimeout(() => {
          if(availableSymbols === undefined || availableSymbols === null) {
            history.push('/')
          }
        }, 2000)
      }
    } else {
      setTimeout(() => {
        if(availableSymbols === undefined || availableSymbols === null) {
          history.push('/')
        }
      }, 2000)
    }

    if(savedStrats.length==0){
      fetch('/auth/fetch_saved_strats', {method: 'GET'})
      .then(res => {return res.text()}).then(response => {
        try {
          let r = JSON.parse(response);
          // console.log("Data is")
          // console.log(r['data'])
          setSavedStrats(r['data']);
        } catch {
          console.log(response);
        }
      });
    }
    // console.log('Socket id is')
    // console.log(socket.id)
    socket.on("backtester_update", msg => {
      if (msg.flash){
        displayFlashMessage(msg);
      }
      if(msg.price_fig){
        setFigure({
          indicators_fig:JSON.parse(msg.indicators_fig),
          equity_fig:JSON.parse(msg.equity_fig),
          price_fig:JSON.parse(msg.price_fig),
          pnl_fig:JSON.parse(msg.pnl_fig),
          dd_fig:JSON.parse(msg.dd_fig),
          trades: msg.trades,
        });
      }
      else if (msg.fig){
        setFigure(JSON.parse(msg.fig));
      }
      if (msg.results){
        // console.log('Received results!')
        // console.log('msg is')
        // console.log(msg)
        let r = msg.results;
        // console.log(r);
        let convert_to_$ = ['profit_net', 'balance', 'balance_initial', 'gross_loss', 
          'gross_profit', 'profit_avg_trade', 'total_fees_paid', 'profit_net_longs', 
          'profit_net_shorts', 'max_equity']
        convert_to_$.forEach(element => {
          // if(r[element])
          // console.log('typeof', element)
          // console.log(typeof element, r[element])
          // console.log(usdFormatter.format(parseFloat(r[element])))
          r[element] = usdFormatter.format(parseFloat(r[element]))
        })
        let round_to_2 = ['profit_factor', 'pnl_ratio', 
          'winrate', 'pnl_ratio_buy_hold']
        round_to_2.forEach(element => {
          r[element] = Math.round(parseFloat(r[element]) * 100) / 100;
        })
        r['max_drawdown'] = Math.round(r['max_drawdown'] * 1000) / 10 + "%"
        setResults(r);
      }

      if (msg.data === 'finished'){
        setLoading(false);
      } else if (msg.data === 'halted_with_error'){
        setLoading(false);
      } 
    });
  }, []);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let btconf = btConfig;
    btconf['backtester_type'] = backtester
    if(backtester === 'vector'){
      delete btconf['exit_settings']['take_profit']
      delete btconf['exit_settings']['stop_loss_value']
      delete btconf['entry_settings']['reinvest_profits']
    } else if(backtester === 'simple'){
      if (!takeProfitSwitch){
        delete btconf['exit_settings']['take_profit']
      } if (!stopLossSwitch){
        delete btconf['exit_settings']['stop_loss_value']
      } 
      if (amountSwitch){
        btconf['entry_settings']['reinvest_profits'] = reinvestProfitsSwitch
      } else {
        delete btconf['exit_settings']['reinvest_profits']
      }
    } else if(backtester === 'v2'){
        delete btconf['exit_settings']['take_profit']
        delete btconf['exit_settings']['stop_loss_value']
        delete btconf['entry_settings']['reinvest_profits']
    } else {

    }
    let body = {
      exchange: exchange,
      symbol: symbol.value, 
      timeframe: timeframe, 
      start_time: new Date(startTime).getTime(), 
      end_time: new Date(endTime).getTime(), 
      bt_config: btconf
    }
    fetch('/api/backtest', {
      method: 'POST', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }).then(res => res.json()).then(response => {
      if(response['flash']) {
        displayFlashMessage(response);
      } if (!response['ok']){
        setLoading(false);
      }
    });
  };

  const runSavedBacktest = (savedSolution) => {
    let sol = savedSolution;
    let st = new Date(sol['start_time']) //.getTime()
    let et = new Date(sol['end_time'])  //.getTime()
    let symb = sol['symbol']
    let id = sol['id']
    let exch = sol['exchange']
    let tf = sol['timeframe']
    let bConf = Object.assign({}, btConfig)
    bConf['strategy']['class'] = sol['strategy_name']
    bConf['strategy']['params'] = sol['strategy_params']
    setStrategy(sol['strategy_name']);
    setExchange(exch);
    setSymbol({"value":symb, "label":symb});
    setTimeframe(tf);
    setStartTime(st.toJSON().slice(0,10));
    setEndTime(et.toJSON().slice(0,10));
    setBtConfig(bConf);
    window.scrollTo(0, 0)
  }

  const onJsonChange = (key, value, parent, data) => {
    setBtConfig(data)
  }

  const updateToAvailableSymbols = (exchange_id) => {
    if(exchange_id === 'binance-spot'){
      exchange_id = 'binance'
    }
    try {
      let symbolOptions = savedDataOptions[exchange_id];
      let newSymbols = Object.keys(symbolOptions).filter(symb => 
        symbolOptions[symb]['we_have'])
      setAvailableSymbols(newSymbols.map(s => { return {'label':s, 'value':s}}))
    } catch { }
  }
  
  const changeExchange = (event) => {
    if(!symbolsSwitch){
      updateToAvailableSymbols(event.target.value)
    } else {
      setAvailableSymbols(pairOptions[event.target.value])
    }
    setExchange(event.target.value);
    setSymbol(null); 
  }

  const changeStrategy = (event) => {
    setStrategy(event.target.value);
    let newBtConfig = btConfig;
    newBtConfig['strategy']['class'] = event.target.value;

    let strat_params = default_strategy_params[event.target.value];
    let bt_strat_params = {}
    Object.keys(strat_params).forEach(element => bt_strat_params[element] = strat_params[element]['default'])
    newBtConfig['strategy']['params'] = bt_strat_params;
    setBtConfig(newBtConfig)
  }

  const handleAmtSwitchChange = () => {
    {
      let btconf = btConfig;
      if(amountSwitch){
        btconf['entry_settings']['trade_amount'] = 100
        delete btconf['entry_settings']['initial_entry_allocation']
      } else {
        btconf['entry_settings']['initial_entry_allocation'] = 100
        delete btconf['entry_settings']['trade_amount']
      }
      setAmountSwitch(!amountSwitch)
      setBtConfig(btconf)
    }
  }

  const handleTpSwitchChange = () => {
    let btconf = btConfig;
    if (takeProfitSwitch){
      delete btconf['exit_settings']['take_profit']
    } else {
      btconf['exit_settings']['take_profit'] = 10
    }
    setTakeProfitSwitch(!takeProfitSwitch)
    setBtConfig(btconf)
  }

  const handleSlSwitchChange = () => {
    let btconf = btConfig;
    if (stopLossSwitch){
      delete btconf['exit_settings']['stop_loss_value']
      delete btconf['exit_settings']['trailing_stop_loss']
    } else {
      btconf['exit_settings']['stop_loss_value'] = 80
      btconf['exit_settings']['trailing_stop_loss'] = false
    }
    setStopLossSwitch(!stopLossSwitch)
    setBtConfig(btconf)
  }

  const selectSymbol = (data) => {
    let exchange_id = exchange;
    let symb = data['value'];
    if(exchange === 'binance-spot'){
      exchange_id = 'binance'
    }
    let current_min_date = startTime;
    try {
      let symbol_info = savedDataOptions[exchange_id][symb];
      let ts = symbol_info['exchange_info']['start_timestamp']
      if(ts > 0){
        current_min_date = new Date(ts).toISOString().split("T")[0]
        setMinPossibleDate(current_min_date)
      }
    } catch {}
    setSymbol(data);
    let new_start_time = latestDate(startTime, current_min_date);
    setStartTime(new_start_time);
  }

  const changeStartTime = (value) => {
    try {
      let strVal = new Date(value).toISOString().split("T")[0]
      let minDateStr = new Date(minPossibleDate).toISOString().split("T")[0]
      let newDate = latestDate(strVal, minDateStr);
      let maxDateStr = new Date(maxPossibleDate).toISOString().split("T")[0]
      newDate = earliestDate(newDate, maxDateStr);
      setStartTime(newDate)
    } catch {
      setStartTime(value)
    }
    
  }

  const changeEndTime = (value) => {
    try {
      let strVal = new Date(value).toISOString().split("T")[0]
      let minDateStr = new Date(minPossibleDate).toISOString().split("T")[0]
      let newDate = latestDate(strVal, minDateStr);
      let maxDateStr = new Date(maxPossibleDate).toISOString().split("T")[0]
      newDate = earliestDate(newDate, maxDateStr);
      setEndTime(newDate)
    } catch {
      setEndTime(value)
    }
  }

  const handleSymbolsSwitchChange = () => {
    if(symbolsSwitch){
      updateToAvailableSymbols(exchange)
    } else {
      setAvailableSymbols(pairOptions[exchange])
    }
    setSymbolsSwitch(!symbolsSwitch)
  }


  return (
    <Container style={{padding:10}}>
      <Form className="Pretty-container">
        <Row style={{display:'flex', flexDirection:'row'}}>
          <Col style={{margin:10}}>
            {availableSymbols === undefined 
              ? <>
                  <Form.Label> If Symbol dropdown doesn't appear </Form.Label> 
                  <Form.Label> Go Home and return here </Form.Label>
                </>: 
              <div style={{display:'flex', flexDirection:'row'}}>
                <div style={{width:'30%'}}> 
                  <Form.Label>All</Form.Label>
                  <Form.Check 
                    style={{marginTop:8, marginLeft:5}}
                    id="symbols_switch" type="switch"
                    checked={symbolsSwitch}
                    onChange={() => handleSymbolsSwitchChange()}
                  />
                </div>
                <div style={{width:'100%'}}> 
                { symbolsSwitch  
                  ? <Form.Label> All Symbols </Form.Label>
                  : <Form.Label> Loaded Symbols </Form.Label>}
                  <Select value={symbol}
                    styles={symbolSelectColourStyles(exchange, savedDataOptions)} 
                    options={availableSymbols} 
                    onChange={(value) =>{selectSymbol(value)}} />
                  <Form.Text className="text-muted">
                    {availableSymbols.length} symbols
                  </Form.Text>
                </div>
              </div>
            }
          </Col>
          <Col style={{margin:10}}>
            <Form.Label>Exchange</Form.Label>
            <Form.Control as="select" value={exchange} onChange={(event) => changeExchange(event)}>
              <option value="binance-spot">Binance (Spot)</option>
              <option value="binance-future">Binance (Futures)</option>
              <option value="bitstamp">Bitstamp</option>
              <option value="ftx">FTX</option>
              <option value="coinbase" disabled>Coinbase</option>
              <option value="kraken" disabled>Kraken</option>
            </Form.Control>
          </Col>
          <Col style={{margin:10}}>
            <Form.Label>Strategy</Form.Label>
            <Form.Control as="select" value={strategy} onChange={(event) =>changeStrategy(event)}>
              {available_strategies.map((strat) => {
                return <option key={strat} value={strat}>{strat}</option>
              })}
            </Form.Control>
          </Col>
        </Row>
        <Row style={{display:'flex', flexDirection:'row'}}>
          <Col style={{margin:10}}>
            <Form.Label>Timeframe</Form.Label>
            <Form.Control as="select" value={timeframe} onChange={(event) =>{setTimeframe(event.target.value)}}>
                {[1, 3, 5, 15, 30, 45, 75, 90, 135, 150].map(opt =>
                  <option key={opt+"min"} value={opt+"min"}>{opt} Min</option>)}
                {[1, 2, 3, 4, 6, 8, 12].map(opt =>
                  <option key={opt+"h"} value={opt+"h"}>{opt} H</option>)}
                <option value={'1D'}>1 Day</option>
                <option value={'3D'}>3 Days</option>
                <option value={'1W'}>1 Week</option>
            </Form.Control>
          </Col>
          <Col style={{margin:10}}>
            <Form.Label>Start Time</Form.Label>
            <Form.Control 
              type="date" min={minPossibleDate} max={maxPossibleDate} 
              value={startTime} onChange={(event) =>{changeStartTime(event.target.value)}} />
          </Col>
          <Col style={{margin:10}}>
            <Form.Label>End Time</Form.Label>
            <Form.Control type="date" min={minPossibleDate} max={maxPossibleDate} 
              value={endTime} onChange={(event) =>{changeEndTime(event.target.value)}} />
          </Col>
        </Row>
        <Row>
          <Col style={{margin:10}}>
            <Form.Label> Backtester Type </Form.Label>
            <Form.Control as="select" value={backtester} onChange={(event) =>{setBacktester(event.target.value)}}>
              <option value="simple">Simple Backtester</option>
              <option value="vector">Vector Backtester</option>
              <option value="v2">Backtester V2</option>
              <option value="trader" disabled>Trader Backtester</option>
            </Form.Control>
            {backtester === 'simple'
            ? <Form.Label> 
                <b>Simple backtester</b>, can go <b>long only</b> or <b>short only </b> 
                but not both, is slower but has more features. 
              </Form.Label>
            : backtester === 'vector'
            ? <Form.Label> 
                <b>Vector backtester</b> is the fastest, can go <b>long</b>, 
                <b> short</b>, or <b>both long and short</b>, but otherwise has less features (only accumulated PnL). 
              </Form.Label>
            : backtester === 'v2'
            ? <Form.Label> 
                <b>Backtester V2.</b> speed. fixed size. extra features. 
              </Form.Label>
            : <Form.Label> 
                <b>Trader backtester</b> is the slowest, but most accurately simulates the trading environment. 
              </Form.Label> 
            }
          </Col>
        </Row>
        {backtester === 'simple' || backtester === 'trader'?
          <Row>
            <Col style={{margin:10}}>
              <Form.Label> Fixed Amount / % Equity </Form.Label>
              <Form.Check 
                id="amt_switch" type="switch"
                checked={amountSwitch}
                onChange={() => handleAmtSwitchChange()}
              />
            </Col>
            <Col style={{margin:10}}>
              <Form.Label> Enable Take Profit ? </Form.Label>
              <Form.Check 
                id="tp_switch" type="switch"
                checked={takeProfitSwitch}
                onChange={() => handleTpSwitchChange()}
              />
            </Col>
            <Col style={{margin:10}}>
              <Form.Label> Enable Stop Loss ? </Form.Label>
              <Form.Check 
                id="sl_switch" type="switch"
                checked={stopLossSwitch}
                onChange={() => handleSlSwitchChange()}
              />
            </Col>
            {amountSwitch &&
              <Col style={{margin:10}}>
                <Form.Label style={!amountSwitch?{color:'grey'}:{color:'black'}}> Reinvest Profits ? </Form.Label>
                <Form.Check 
                  id="reinvest_switch" type="switch"
                  disabled={!amountSwitch}
                  checked={reinvestProfitsSwitch}
                  onChange={() => setReinvestProfitsSwitch(!reinvestProfitsSwitch)}
                />
              </Col>
            }
          </Row>
          : null
        }
        <Row>
          <Col style={{margin:10}}>
            <Button variant="primary" type="submit" 
              onClick={handleSubmit} disabled={loading}>
              {loading && <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />}
              Start Backtest
            </Button>
          </Col>
        </Row>
        <Row>
          <Col style={{margin:10, marginTop:20}}>
            <Accordion>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Backtester Options
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="Flex-central-central">
                    <JSONEditor
                        data={btConfig}
                        collapsible
                        onChange={onJsonChange} 
                        showRemoveButton={false}
                        showAddButton={false}
                      />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Form>
      <BacktesterResults figure={figure} results={results} />
      <Col>
          {savedStrats === null || savedStrats.length == []
          ? savedStrats 
          : <div className="Pretty-container">
              <SavedStrategyParams 
                displayFlashMessage={displayFlashMessage}
                data={savedStrats} 
                runBacktest={runSavedBacktest}
                />
          </div>}
      </Col>
    </Container>
  )
}

export default Backtester;