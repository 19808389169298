
let default_strategy_params = {
  'RaduGold': {
      'rngval1' : {'default' : 0, 'min' : 1, 'max' : 15 }, 
      'rngval2' : {'default' : 0, 'min' : 5, 'max' : 35 }, 
      'linReg' : {'default' : 0, 'min' : 1, 'max' : 15 }, 
      'ch65' : {'default' : 0, 'min' : 1, 'max' : 35 }, 
      'lb1' : {'default' : 0, 'min' : 1, 'max' : 15 },
      'lb2' : {'default' : 0, 'min' : 5, 'max' : 25 },
      'lb4' : {'default' : 0, 'min' : 15, 'max' : 40 }
  },
  'RaduGoldOriginal': {
      'rngval1' : {'default' : 0, 'min' : 1, 'max' : 15 }, 
      'rngval2' : {'default' : 0, 'min' : 5, 'max' : 35 }, 
      'linReg' : {'default' : 0, 'min' : 1, 'max' : 15 }, 
      'ch65' : {'default' : 0, 'min' : 1, 'max' : 35 }, 
      'lb1' : {'default' : 0, 'min' : 1, 'max' : 15 },
      'lb2' : {'default' : 0, 'min' : 5, 'max' : 25 },
      'lb4' : {'default' : 0, 'min' : 15, 'max' : 40 }
  },
  // 'ChadEmaGap' : {
  //   'fast_ema_len' : {'default' : 4, 'min' : 2, 'max' : 10 },
  //   'gap_size' : {'default' : 0.001, 'min' : 0.0001, 'max' : 10 },
  //   'inverted' : {'default': false },
  //   'include_volume_spike' : {'default': false }
  // },
  // 'ChadEmaCross' : {
  //   'fast_ema_len' : {'default' : 4, 'min' : 2, 'max' : 10 },
  //   'slow_ema_len' : {'default' : 8, 'min' : 4, 'max' : 20 },
  // },
  'RaduDom' : {
    'hold_count' : {'default' : 0, 'min' : 1, 'max' : 25 }, 
    'dom' : {'default' : 0, 'min' : 1, 'max' : 31 }, 
    // 'ls' : {'default': true, 'min': 1, max:},
  },
  'HAMomentum' : {},
  // 'Radu004': {}
}


let init_bt_config = {
  'starting_balance' : 10_000,
  'strategy': {
      'class': 'RaduGoldOriginal',
      'params': {
          'rngval1' : 5, 
          'rngval2' : 16, 
          'linReg' : 5, 
          'ch65' : 2, 
          'lb1' : 22, 
          'lb2' : 7, 
          'lb4' : 3, 
          'enter_again' : 0, 
          // 'timeframe' : '1h'
      }
  },
  'entry_settings' : {
      // 'initial_entry_allocation': 100,
      'trade_amount': 10_000,
      // 'use_base_amount': false,
      'fee': 0.3,
      // 'reinvest_profits': true,
      'go_long': true,
      'go_short': false
  },
  'exit_settings' : {
      // 'take_profit' : 100,
      // 'trailing_stop_loss': false,
      // 'stop_loss_value': 20,
      'exit_on_signal': true,
  //     'sell_on_end': true
  }
}


let init_optim_config = {
  'trade_amount' : 10_000,
  'n_generations' : 20,
  'generation_size' : 400,
  'mutation_probability' : 0.7,
  'entry_settings' : {
      // 'use_base_amount': false,
      'fee': 0.3,
      'go_long': true,
      'go_short': false
  },
  'exit_settings' : {
  //     'take_profit' : 100_000,
  //     // 'trailing_stop_loss': true,
  //     'stop_loss_value': 5,
      'exit_on_signal': true,
  //     'sell_on_end': true
  }
}

let init_evolver_config = {
  'trade_amount' : 10_000,
  // 'generations' : 50,
  // 'population_size' : 1_000,
  // 'p_crossover' : 0.6,
  // 'p_subtree_mutation': 0.15,
  // 'p_hoist_mutation' : 0.05,
  // 'p_point_mutation' : 0.15,
  // 'parsimony_coefficient' : 0.1,
  'entry_settings' : {
      // 'use_base_amount': false,
      'trade_amount' : 10_000,
      'fee': 0.3,
      'go_long': true,
      'go_short': false
  },
  'exit_settings' : {
  //     'take_profit' : 100_000,
  //     // 'trailing_stop_loss': true,
  //     'stop_loss_value': 5,
      'exit_on_signal': true,
  //     'sell_on_end': true
  }
}


const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const symbolSelectColourStyles = (exchange, savedDataOptions) => {
  return {
    // control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let symb = data.value;
      let exchange_id = exchange;
      if(exchange === 'binance-spot'){
        exchange_id = 'binance'
      }
      try {
        if (savedDataOptions[exchange_id][symb]['we_have']){
          if(isSelected){
            return {...styles, fontWeight: 'bold'}
          }
          return {
            ...styles, fontWeight: 'bold', backgroundColor: 'rgba(0, 255, 0, 0.2)',
          }
        }
        else return {...styles}
      } catch {
        return {...styles}
      }
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => 
    {
      let symb = data.value;
      let exchange_id = exchange;
      if(exchange === 'binance-spot'){
        exchange_id = 'binance'
      }
      try {
        if (savedDataOptions[exchange_id][symb]['we_have']){
        return ({ ...styles, ...dot('green') })
        } else {
          return ({ ...styles, ...dot() })
        }
      } catch {
        return ({ ...styles, ...dot() })
      }
    }
  }
};


var usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

var btcFormatter = new Intl.NumberFormat('en-US', { 
  style: 'currency', 
  currency: 'BTC' 
});

function latestDate(dateString1, dateString2) {
  if (Date.parse(dateString1) < Date.parse(dateString2))
    return dateString2;
  return dateString1
}

function earliestDate(dateString1, dateString2) {
  if (Date.parse(dateString1) > Date.parse(dateString2))
    return dateString2;
  return dateString1
}

function getTomorrowsDate(){
  let date = new Date();
  date.setDate(date.getDate() + 1);
  return date;
}


export { init_bt_config, init_optim_config, init_evolver_config,
  default_strategy_params, dot, latestDate, earliestDate,
  symbolSelectColourStyles, usdFormatter, btcFormatter, getTomorrowsDate };