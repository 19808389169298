import React from 'react';
import Col from 'react-bootstrap/Col';
import Plot from 'react-plotly.js';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import ReactJson from 'react-json-view';
import TableTradeEntry from '../components/TableTradeEntry';

export default function BacktesterResults(props) {
  const {figure, results} = props;

  return (figure === null 
    ? figure : figure.price_fig ?
    <Col>
      <div className="Pretty-container">
        <Tabs defaultActiveKey="price_signals" id="uncontrolled-tab-example" className="mb-3">
          <Tab eventKey="price_signals" title="Main View">
            <Plot data={figure.price_fig['data']} layout={figure.price_fig['layout']} style={{width:'100%'}}/>
          </Tab>
          <Tab eventKey="pnl" title="PNL">
            <Plot data={figure.pnl_fig['data']} layout={figure.pnl_fig['layout']} style={{width:'100%'}}/>
          </Tab>
          <Tab eventKey="dd" title="Drawdown">
            <Plot data={figure.dd_fig['data']} layout={figure.dd_fig['layout']} style={{width:'100%'}}/>
          </Tab>
          <Tab eventKey="indicators" title="Indicators">
            <Plot data={figure.indicators_fig['data']} layout={figure.indicators_fig['layout']} style={{width:'100%'}}/>
          </Tab>
          <Tab eventKey="equity" title="Equity">
            <Plot data={figure.equity_fig['data']} layout={figure.equity_fig['layout']} style={{width:'100%'}}/>
          </Tab>
          <Tab eventKey="trades" title="Trades">
            <div style={{overflow: 'scroll', height:'40vh'}}>
              <Table striped bordered hover>
                <thead> <tr> <th>#</th> <th>Type</th> <th>Time</th> <th>Price</th> <th>Profit</th> </tr> </thead>
                {figure.trades.map(trade => { return <TableTradeEntry trade={trade}/>
                  })}
              </Table>
            </div>
          </Tab>
          {results === null 
          ? results 
          : <Tab eventKey="results" title="Raw Results">
                <div style={{textAlign: 'left', fontSize:16,
                  display:'flex', alignSelf:'center', 
                  alignItems:'center', justifyContent:'center'}}> 
                  <ReactJson 
                    src={results} 
                    name="results"
                    quotesOnKeys={false}
                    collapsed={1}
                    indentWidth={20}
                    collapseStringsAfterLength={18}
                    displayDataTypes={false}
                    />
                </div>
            </Tab>}
        </Tabs>
      </div>
    </Col>
    : <>
      <Col>
        <div className="Pretty-container">
          <Plot data={figure['data']} layout={figure['layout']} style={{width:'100%'}}/>
        </div>
      </Col>
      <Col>
          {results === null 
          ? results 
          : <div className="Pretty-container">
              <ReactJson 
                src={results} 
                name="results"
                displayDataTypes={false}
                />
          </div>}
      </Col>
    </>
  )
}
