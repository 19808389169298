import React from 'react'

export default function RealTableTradeEntry(props) {

  let {trade} = props;
  let profit = 1
  if(trade.exit !== null){
    profit = trade.exit.price / trade.entry.price
  }
  let displayProfit = Math.round((profit - 1) * 10000) / 100
  let fontColor = 'green'
  if (trade.entry.side === 'buy' && profit < 1){
    fontColor = 'red'
  } else if (trade.entry.side === 'sell' && profit > 1){
    fontColor = 'red'
  }
  return <tbody key={trade.number}>
    {/* <hr/> */}
          {trade.entry && trade.entry.status != 'canceled' && trade.entry.executed_quantity != 0 ?
            <tr>
              <td>{trade.number}</td>
              <td>{trade.entry.position_type}</td>
              <td>{trade.entry.timestamp}</td>
              <td>{trade.entry.status}</td>
              <td>{Math.round(trade.entry.price*100000) / 100000}</td>
              <td>{Math.round(trade.entry.executed_quantity*100000) / 100000} / {Math.round(trade.entry.original_quantity*100000) / 100000}</td>
              <td></td>
            </tr>
            : null}
          {trade.exit && trade.exit.status != 'canceled' && trade.exit.executed_quantity != 0 ?
            <tr>
              <td> </td>
              <td>{trade.exit.position_type}</td>
              <td>{trade.exit.timestamp}</td>
              <td>{trade.exit.status}</td>
              <td>{Math.round(trade.exit.price*100000) / 100000}</td>
              <td>{Math.round(trade.exit.executed_quantity*100000) / 100000} / {Math.round(trade.exit.original_quantity*100000) / 100000}</td>
              {fontColor == 'red' ? 
              <td style={{color:'red', fontWeight:'bold' }}> {displayProfit} %</td>
              :
              <td style={{color:'green', fontWeight:'bold' }}> {displayProfit} %</td>}
            </tr>
          : null}
        </tbody>
      
}
