import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';

let status_colors = {
  'warning': 'gold',
  'success' : 'green',
  'fail' : 'indianred'
}

const BotRunInfo = (props) => {
  // console.log(props)
  // const viewLogs = () => {
  //   console.log(props.logs.slice(-1)[0])
  // } 
  return (
    <div className='Pretty-container-bordered'>
      <p style={{fontSize:16, margin:2, fontWeight: 'bold'}}> Run Info </p>
      <p style={{fontSize:14, margin:2}}> {new Date(props.start_timestamp * 1000).toLocaleDateString()} </p>
      <p style={{fontSize:14, margin:2}}> Started {new Date(props.start_timestamp * 1000).toLocaleTimeString()} </p>
      <p style={{fontSize:14, margin:2}}> Ended {new Date(props.end_timestamp * 1000).toLocaleTimeString()} </p>
      <p style={{fontSize:14, margin:2}}> Time {Math.round(100 * (props.end_timestamp - props.start_timestamp)) / 100} seconds </p>
      <p style={{fontSize:16, margin:2, fontWeight: 'bold'}}> Status </p>
      <p style={{fontSize:14, margin:2, fontWeight: 'bold', color:status_colors[props.status]}}> {props.status} </p>
      <p style={{fontSize:14, margin:2}}> Click on square to print logs in the console. </p>
      {props.status !== 'success' &&
        <>
          {/* <Button variant="primary" style={{margin:3}} onClick={viewLogs}>
            View Logs
          </Button> */}
        </>
      }
    </div>
)};

export default BotRunInfo;