import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';

import BotsTable from '../components/BotsTable';
import Exchanges from '../components/Exchanges';
import NewExchangeForm from '../components/NewExchangeForm';

import '../App.css';

function Home(props){
  const [exchanges, setExchanges] = useState([]);
  const {socket, displayFlashMessage, setLoadScreen} = props;

  return (
    <div className="Flex-central-central">
      <div style={{display:'flex'}}>
      </div>
      <div style={{display:'flex', flexDirection:'column'}}>
        <div style={{display:'flex'}}>
          <BotsTable setLoadScreen={setLoadScreen} socket={socket} displayFlashMessage={displayFlashMessage}/>
        </div>
        <div style={{display:'flex'}}>
          <NewExchangeForm displayFlashMessage={displayFlashMessage}/>
          <Exchanges exchanges={exchanges} displayFlashMessage={displayFlashMessage}/>
        </div>
      </div>
    </div>
  );
}

export default Home;