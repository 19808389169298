import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import '../App.css';

function NewExchangeForm(props){
  const { displayFlashMessage } = props;

  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [extraParameter, setExtraParameter] = useState('');
  const [exchangeName, setExchangeName] = useState('');
  const [exchange, setExchange] = useState('binance');
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    fetch('/auth/add_exchange', {
      method: 'POST', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        exchange_id: exchange, 
        name:exchangeName, 
        api_key:apiKey, 
        api_secret: apiSecret, 
        extra_parameter: extraParameter, 
        api_password: ''})
    }).then(res => res.json()).then(response => {
      setLoading(false);
      if(response['flash']) {
        displayFlashMessage(response);
      }
    });
    setValidated(true);
  };

  return (
    <div className="Pretty-container">
      <Form validated={validated}>
        <Form.Group className="mb-6">
          <Form.Label>EC Name</Form.Label>
          <Form.Control autoComplete="off" placeholder="Enter name" 
            onChange={(e) => setExchangeName(e.target.value)}/>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Exchange</Form.Label>
          <Form.Control as="select" value={exchange} 
            onChange={(e) => setExchange(e.target.value)}>
            <option value="ftx">FTX</option>
            <option value="kraken">Kraken</option>
            <option value="binance">Binance</option>
            <option value="independentreserve">Ind. Res.</option>
          </Form.Control>
        </Form.Group>
        
        {exchange === 'ftx' && 
          <Form.Group className="mb-3">
            <Form.Label>Account Name</Form.Label>
            <Form.Control autoComplete="off" placeholder="Account Name" 
              onChange={(e) => setExtraParameter(e.target.value)}/>
          </Form.Group>
        }
        <Form.Group className="mb-3">
          <Form.Label>API Key</Form.Label>
          <Form.Control autoComplete="off" placeholder="API Key" 
            onChange={(e) => setApiKey(e.target.value)}/>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>API Secret</Form.Label>
          <Form.Control autoComplete="off" placeholder="API Secret" 
            onChange={(e) => setApiSecret(e.target.value)}/>
        </Form.Group>

        <Button variant="primary" type="submit" onClick={handleSubmit} disabled={loading}>
          {loading && <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />}
          Submit
        </Button>
      </Form>
    </div>
  )
}

export default NewExchangeForm;