import React, {useState, useEffect} from 'react';
import FormLabel from 'react-bootstrap/FormLabel';
import FormCheck from 'react-bootstrap/FormCheck';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import DataTable from 'react-data-table-component';
// import Plot from 'react-plotly.js';
import { useHistory } from "react-router-dom";
import '../App.css';
import ReactJson from 'react-json-view';
import { JSONEditor } from 'react-json-editor-viewer';
import { Chart } from 'react-charts';

const SmallPnlChart = (props) => {
  const data = React.useMemo(
    () => [
      {
        label: 'Series 1',
        color: props.pnl>0?'rgba(63, 195, 128)':(
          props.pnl===0?'rgba(248, 148, 6)'
          :'rgba(242, 38, 19)'
        ),
        data: props.data
          ? props.data
          : [[0, 1], [1, 2], [2, -4], [3, 2], [4, 7]]
      },
      // {
      //   label: 'Series 2',
      //   data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
      // }
    ],
    [props.data]
  )

  const series = React.useMemo(
    () => ({
      showPoints: false
    }),
    []
  )
  const axes = React.useMemo(
    () => [
      {
        primary: true,
        position: 'bottom',
        type: 'time',
        show: false
      },
      { position: 'left', type: 'linear', show: false }
    ],
    [])
    return (
        <div style={{width:100, height:40}}>
          <Chart data={data} series={series} axes={axes} />
        </div>
    )
}

function round(number, digits) {
  return Math.round(number * 10**digits) / 10**digits;
}

function toPercentage(number) {
  return Math.round(number * 1000) / 10;
}

const columns = [
{
    name: 'Name',
    selector: row => row['name'],
    width:'200px',
    sortable: true,
  },
  {
    name: 'Exchange',
    selector: row => row['exchange_name'],
    sortable: true,
    right: true,
  },  {
    name: 'PNL (%)',
    selector: row => {
      let pnl = parseFloat(round(JSON.parse(row['bot_stats'])['pnl_ratio'], 5))
      return toPercentage(pnl);
    },
    sortable: true,
    right: true,
    conditionalCellStyles: [
			{
				when: row => {
          let pnl = parseFloat(round(JSON.parse(row['bot_stats'])['pnl_ratio'], 5))
          return pnl > 0
        },
				style: {
					color: 'rgba(63, 195, 128)',
          fontWeight:'bold'
				},
			},
			{
				when: row => {
          let pnl = parseFloat(round(JSON.parse(row['bot_stats'])['pnl_ratio'], 5))
          return pnl === 0
        },
				style: {
					color: 'rgba(248, 148, 6)',
          fontWeight:'bold'
				},
			},
			{
				when: row => {
          let pnl = parseFloat(round(JSON.parse(row['bot_stats'])['pnl_ratio'], 5))
          return pnl < 0
        },
				style: {
					color: 'rgba(242, 38, 19)',
          fontWeight:'bold'
				},
			},
		],
  },
  {
    name: 'Symbol',
    selector: row => row['symbol'],
    sortable: true,
    right: true,
  },
  // {
  //   name: 'Strategy',
  //   selector: row => JSON.parse(row['strategy'])['name'],
  //   sortable: true,
  //   right: true,
  // },
  // {
  //   name: 'Alloc',
  //   selector: row => Math.round(row['current_balance'] * 100000) / 100000,
  //   sortable: true,
  //   right: true,
  // },
  {
    name: 'Orders',
    selector: row => row['n_orders'],
    sortable: true,
    right: true,
  },
  {
    name: 'On',
    selector: row => row['is_running'] === true ? 'ON' : 'OFF',
    sortable: true,
    right: true,
  },
  {
    name: '',
    selector: row => {
      let pnl = parseFloat(round(JSON.parse(row['bot_stats'])['pnl_ratio'], 5))
      return <SmallPnlChart data={JSON.parse(row['latest_pnl_graph'])} pnl={pnl}/>
    },
    // sortable: true,
    right: true,
  },
  // {
  //   name: '',
  //   right: true,
  //   cell: row => (
  //     <div key={row.latest_update_time} className="update-spinner" style={{minWidth:50, maxWidth:50, fontWeight: 'bold' }} > 
  //       <Spinner animation="grow" size="sm"/> 
  //     </div>),
  // },
];

const BotRow = (props) => {
  const {id, displayFlashMessage, settings, strategy, own} = props['data'];
  const history = useHistory();
  const [data, setData] = useState();
  const [editSwitch, setEditSwitch] = useState(false);

  useEffect(() => {
    if(data === undefined){
      let newData = Object.assign({}, props['data']);
      newData['settings'] = JSON.parse(settings)
      newData['strategy'] = JSON.parse(strategy)
      newData['bot_stats'] = JSON.parse(newData['bot_stats'])
      delete newData['displayFlashMessage']
      delete newData['logs']
      delete newData['latest_pnl_graph']
      setData(newData)
    }
  }, [data, settings, props, strategy])

  const onJsonChange = (key, value, parent, data) => {
    setData(data)
  }

  const editBot = () => {
    fetch('/auth/update_bot', {
      method: 'POST', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({bot_id: id, data:data})
    }).then(res => res.json()).then(response => {
      if(!response['ok']){
        console.log('nok when updating bot');
      } else {
        console.log('ok when updating bot');
      }
      if (response['flash']){
        displayFlashMessage(response)
      }
    });
  } 
  const pauseBot = () => {
    fetch('/auth/pause_bot', {
      method: 'POST', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({bot_id: id})
    }).then(res => res.json()).then(response => {
      if(!response['ok']){
        console.log('nok when pausing bot');
      } else {
        console.log('ok when pausing bot');
      }
      if (response['flash']){
        displayFlashMessage(response)
      }
    });
  } 
  const startBot = () => {
    fetch('/auth/start_bot', {
      method: 'POST', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        bot_id: id, 
        repeat: 50, 
        interval: 100,
        scheduled_time: Math.floor((new Date().getTime())/1000)
        })
    }).then(res => res.json()).then(response => {
      if(!response['ok']){
        console.log('nok')
      } else {
        console.log('ok')
      }
      if (response['flash']){
        displayFlashMessage(response)
      }
    });
  };
  const deleteBot = () => {
    if (window.confirm("Are you sure you want to delete this bot? \nWarning:" +
      " This will delete the bot, its settings, its orders and all the stats" +
      " collected about its runs. \n <b>This action is irreversible.</b>.")){
      fetch('/auth/delete_bot', {
          method: 'POST', 
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({bot_id: id})
        }).then(res => res.json()).then(response => {
          if(!response['ok']){
            console.log('nok')
          } else {
            console.log('ok')
          }
          if (response['flash']){
            displayFlashMessage(response)
          }
        });
    }
  };
  const runOnce = () => {
    if(window.confirm("Are you sure you want to force run bot once?")){
      fetch('/auth/run_bot_once', {
        method: 'POST', 
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({bot_id: id})
      }).then(res => res.json()).then(response => {
        if(!response['ok']){
          console.log('nok')
        } else {
          console.log('ok')
        }
        if (response['flash']){
          displayFlashMessage(response)
        }
      });
    }
  };
  const openBotPage = () => {
    history.push(`/bots/${id}`);
  };
  return (
    <div style={{display:'flex', flexDirection:'column'}}>
      <FormLabel> Edit Bot Settings </FormLabel>
      <FormCheck id="edit_switch" type="switch"
        checked={editSwitch}
        onChange={() => setEditSwitch(!editSwitch)}
      />
      <div style={{textAlign: 'left', fontSize:16,
          margin:10, 
          display:'flex', alignSelf:'center', 
          alignItems:'center', justifyContent:'center'}}> 
          {editSwitch
          ? 
          <JSONEditor
              data={data}
              collapsible
              onChange={onJsonChange} 
              showRemoveButton={false}
              showAddButton={false}
            />
          : <ReactJson 
              src={data} 
              name="results"
              quotesOnKeys={false}
              collapsed={1}
              indentWidth={20}
              // collapseStringsAfterLength={18}
              displayDataTypes={false}
              />
          }
        </div>
      <hr/>
      <div style={{display:'flex', flex:1, justifyContent:'flex-end'}}>
        {own && <>
          <Button variant="warning" style={{margin:3}} onClick={editBot}>
            Edit
          </Button>
          <Button variant="warning" style={{margin:3}} onClick={runOnce}>
            Run Once
          </Button>
          <Button variant="primary" style={{margin:3}} onClick={startBot}>
            Start
          </Button>
          <Button variant="secondary" style={{margin:3}} onClick={pauseBot}>
            Pause
          </Button>
          <Button variant="danger" style={{margin:3}} onClick={deleteBot}>
            Delete
          </Button>
        </>}
        <Button variant="info" style={{margin:3}} onClick={openBotPage}>
          More Info 
        </Button>
        <Button variant="info" style={{margin:3}} onClick={() => {
          console.log(props);
        }}>
          Log
        </Button>
      </div>
      <hr/>
    </div>
  )
}

const ToggleComponent = ({ textOn, textOff, onSwitch }) => {
  const [editSwitch, setEditSwitch] = useState(true);
  return (
    <div style={{display:'flex', flexDirection:'column'}}>
      <FormLabel> {editSwitch?textOn:textOff} </FormLabel>
      <FormCheck id="edit_switch" type="switch"
        checked={editSwitch}
        onChange={() => {
          onSwitch(!editSwitch)
          setEditSwitch(!editSwitch)
        }}
      />
    </div>
  )
}



function BotsTable(props){
  const {socket, displayFlashMessage} = props; 
  const [bots, setBots] = useState([]);
  const [showArchaived, setShowArchaived] = useState(false);
  const [showMine, setShowMine] = useState(true);

  let validBots = [];

  useEffect(() => {
    console.log("GETTING BOTS!!!!")
    fetch('/auth/fetch_bots', {
      method: 'GET', 
      headers: {'Content-Type': 'application/json'},
    }).then(res => res.text()).then(response => {
      console.log("GOT BOTS!!!!")
      try {
        let r = JSON.parse(response);
        // console.log(r);
        let rd = JSON.parse(r['data']);
        console.log(rd);
        let updatedBots = rd.map((item) => {
          return {...item, logs: [], displayFlashMessage:displayFlashMessage}
        })
        validBots = updatedBots
        setBots(updatedBots);
        // console.log(r['data'])
      } catch {
        console.log(response);
      }
    });
  }, []);

  useEffect(() => {
    // Do something when we get an update from the bot runner
    socket.on("bot_update", msg => {
      ///update object in nested array of objects without mutating original source
      let updatedBots = validBots.map((item, index) => {
        if (item.id === msg['id']) {
          return {...item, latest_update: msg, 
            latest_update_time: Math.floor(new Date().getTime() / 1000)}
        }
        return {...item}
      })
      validBots = updatedBots;
      console.log(validBots[0])
      setBots(updatedBots);
    });
    // Do something when we get a log from the trader
    socket.on("bot_log", msg => {
    });
  }, []);

  const subHeaderComponentMemo = React.useMemo(() => {
		return (
			<ToggleComponent 
        textOn={" OWNED"} 
        textOff={"SHARED"} 
        onSwitch={(val) => setShowMine(val)} />
		);
	}, []);

  return (
  <div className="Pretty-container">
      <DataTable
        title="Bots"
        columns={columns}
        data={bots.filter(
          item => (showMine && item.own) || (!showMine && !item.own),
        )}
        expandableRows
        expandableRowsComponent={BotRow}
        highlightOnHover
        Clicked
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        // onRowClicked={clickRow}
        pagination
      />
    </div>
  )

}

export default BotsTable;