import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import DataTable from 'react-data-table-component';
import Plot from 'react-plotly.js';

import '../App.css';

import { usdFormatter, btcFormatter } from '../utils.js';

const columns = [
  {
    name: 'Exchange',
    selector: row => row['exchange_name'],
    sortable: true,
  },
  {
    name: 'Ref',
    selector: row => row['name'],
    sortable: true,
    right: true,
  },
  {
    name: '$BTC',
    selector: row => btcFormatter.format(row['btc_val']),
    sortable: true,
    right: true,
  },
  {
    name: 'Updated',
    selector: row => row['updateTime'],
    sortable: true,
    right: true,
  },
];


function Exchanges(props){
  let {displayFlashMessage} = props;
  const [exchanges, setExchanges] = useState([]);
  // console.log(exchanges);

  useEffect(() => {
    fetch('auth/fetch_exchanges', {method: 'GET', })
    .then(res => {return res.text()}).then(response => {
      try {
        let r = JSON.parse(response);
        setExchanges(r['data']);
      } catch {
        console.log("Error fetching exchanges!")
        // console.log(response);
      }
    });
  }, []);

  const expandRow = (row) => {
    // console.log(row)
    const {plot, id} = row['data'];
    var figure = JSON.parse(plot)
    // console.log(figure['data'])
    // console.log(figure['layout'])
    
    const updateWallet = () => {
      console.log(id)
      fetch('/auth/update_wallet', {
        method: 'POST', 
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({exchange_creds_id: id})
      }).then(res => res.json()).then(response => {
        if(!response['ok']){
          console.log(response);
        } else {
          console.log(response);
          // window.location.reload();
        }
        if (response['flash']){
          displayFlashMessage(response)
        }
      });
      // setValidated(true);
    };
    
    const deleteWallet = () => {
      if(window.confirm("Are you sure you want to delete this Exchange Credential?")){
        // console.log(id)
        fetch('/auth/delete_exchange', {
          method: 'POST', 
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ec_id: id})
        }).then(res => res.json()).then(response => {
          if(!response['ok']){
            console.log(response);
          } else {
            // let userData = response['data'];
            console.log(response);
            // window.location.reload();
            // onSuccesfulLogin(userData);
          }
          if (response['flash']){
            displayFlashMessage(response)
          }
        });
      }
    };
    

    return (
      <div style={{display:'flex', flexDirection:'column'}}>
        <div style={{display:'flex', flex:12, justifyContent:'flex-end'}}>
          <Plot data={figure['data']} layout={figure['layout']} style={{}}/>
        </div>
        <div style={{display:'flex', flex:1, justifyContent:'flex-end'}}>
          <Button variant="primary" style={{margin:3}} onClick={updateWallet}>
            Update 
          </Button>
          <Button variant="danger" style={{margin:3}} onClick={deleteWallet}>
            Delete
          </Button>
        </div>
      </div>
    )
  }
  return (
  <div className="Pretty-container">
      <DataTable
        title="Exchanges"
        columns={columns}
        data={exchanges}
        expandableRows
        expandableRowsComponent={expandRow}
        highlightOnHover
        Clicked
        // onRowClicked={clickRow}
        pagination
      />
    </div>
  )

}

export default Exchanges;